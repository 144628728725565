import { useErpCredentials, useDatCredentials } from "@tm/context-distribution"
import { useEffect, useState } from "react"
import { Box, Button, Dialog, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useHistory } from "react-router"

export function CheckUserCredentialsSet() {
    const [showModal, setShowModal] = useState(false)
    const erpConfig = useErpCredentials()
    const datConfig = useDatCredentials()
    const history = useHistory()

    const { translateText } = useLocalization()

    useEffect(() => {
        // if any of the required passwords is not set, show modal
        const needsErpCredentials = erpConfig?.erpModules && Object.values(erpConfig.erpModules).some((x) => !x.isPasswordSet)
        const needsDatCredentials = datConfig && !datConfig?.isPasswordSet

        if (needsErpCredentials || needsDatCredentials) {
            setShowModal(true)
        }

        // only needed once so we don't need to check on changing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function redirectToUserSettings() {
        setShowModal(false)

        // second part of the url is the scrollTo value
        history.push("/usersettings/credential-settings")
    }

    return (
        <Dialog onOutsideClick={() => setShowModal(false)} open={showModal} position="top" skin="info">
            <Box p={3}>
                <Typography paddingBottom={3} variant="h2">
                    {translateText(1196)}
                </Typography>
                <Typography paddingBottom={2}>{translateText(13453)}</Typography>
                <Button onClick={() => redirectToUserSettings()} size="large" color="primary" value={translateText(13110)} />
            </Box>
        </Dialog>
    )
}
