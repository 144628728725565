import { Article } from "@tm/models"
import { uniqueId } from "@tm/utils"

export function mapSensorArticle(response: any): Article {
    return {
        ...response,
        quantity: 4,
        internalId: response.id,
        id: uniqueId(),
        references: response.references || [],
        information: response.information || [],
        attributes: response.attributes || [],
        vehicleInformation: [],
    }
}
