import { ajaxAuth, notUndefinedOrNull } from "@tm/utils"
import { GetRecommendedArticlesRequest, GetRecommendedArticlesResponse } from "./models"
import { Articles } from "../../index"
import { WebserviceUrl } from "../../webserviceUrls"

export const getRecommendedArticles = (body: GetRecommendedArticlesRequest) => {
    const url = `${WebserviceUrl.CatalogArticles}/GetRecommendedArticles`

    return ajaxAuth<GetRecommendedArticlesResponse>({ url, body, method: "POST" }).then((response) =>
        response?.articles ? response?.articles.map(Articles.mapArticle).filter(notUndefinedOrNull) : []
    )
}
