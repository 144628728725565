import { Layout, LayoutProps } from "@tm/morpheus"
import { NestedCSSProperties } from "typestyle/lib/types"
import { style } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { getLocalization } from "@tm/localization"
import { channel, getWorkTaskId } from "@tm/models"
import { useHeighAdjustment } from "@tm/utils"

function getStyles(): NestedCSSProperties {
    const { margin, opacity } = getStyleTheme()
    return {
        $nest: {
            "&__head": {
                fontSize: "1.5em",
                lineHeight: 1.3,
                marginBottom: margin.s,
                opacity: opacity.primary,
                $nest: {},
            },
            "&__body": {
                position: "relative",
                $nest: {
                    "> .layout__slot-0": {
                        flexDirection: "column",
                    },
                },
            },
            "&__body .widget--h1": {
                height: "8.2em",
            },
            "& .widget__footer": {
                bottom: "0",
            },
        },
    }
}

const translationIdPattern = /\{\{(.*?)\}\}/g

type MultiWidgetLayoutProps = {
    title?: string
    icon?: string
    showOnVehicle?: boolean // If true, only show the module when a vehicle is selected
    heightObservationName?: string // set this to allow the observation of the height of this module, to adjust other component height
}

export default class MultiwidgetLayout extends Layout<MultiWidgetLayoutProps> {
    removeHeightAdjustment: Function

    state: { visible: boolean }

    unsubs: Function[]

    constructor(props: LayoutProps<MultiWidgetLayoutProps>) {
        super(props)
        this.handleRef = this.handleRef.bind(this)
        this.state = { visible: !props.props?.showOnVehicle }
        if (props.props?.showOnVehicle) {
            this.props.setModuleVisibility(false)
            getWorkTaskId((id?: string) => {
                if (id) {
                    this.unsubs = [
                        channel("WORKTASK", id).subscribe(
                            "VEHICLE/LOADED",
                            () => {
                                this.setState({ visible: true })
                                this.props.setModuleVisibility(true)
                            },
                            true
                        ),
                    ]
                }
            })
        }
    }

    componentWillUnmount() {
        this.unsubs && this.unsubs.forEach((unsub) => unsub())
        this.removeHeightAdjustment && this.removeHeightAdjustment()
    }

    renderHeader() {
        const { translate } = getLocalization()
        const { title, icon } = this.props.props || {}

        if (!title) {
            return null
        }
        const text = (title as string).replace(translationIdPattern, ($0, $1) => translate($1) as string)

        return (
            <div className="widget__title">
                {icon && <Icon name={icon} className="widget__icon" />}
                <div className="text">{text}</div>
            </div>
        )
    }

    handleRef(ref: HTMLDivElement) {
        if (ref && this.props.props?.heightObservationName) {
            this.removeHeightAdjustment = useHeighAdjustment().allowHeightObservation(ref, this.props.props.heightObservationName)
        }
    }

    render() {
        if (!this.state.visible) {
            return null
        }
        const className = style(getStyles())

        const header = this.renderHeader()
        const content = this.renderSlot(0)
        const contentSlot2 = this.renderSlot(1)
        const footer = this.renderSlot("footer")

        return (
            <div className={`${className} layout widget`} ref={this.handleRef}>
                <div className="widget__card">
                    <div className="widget__inner">
                        {header && <div className={`${className}__head multiwidget__head widget__header`}>{header}</div>}
                        {content && (
                            <div className={`${className}__body multiwidget__body widget__content`}>
                                {content}
                                {contentSlot2 ?? contentSlot2}
                            </div>
                        )}
                        {footer && <div className={`${className}__foot multiwidget__footer widget__footer`}>{footer}</div>}
                    </div>
                </div>
            </div>
        )
    }
}
