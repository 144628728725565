import { getLanguageIdFromLocalStorage, hasLocaleInLocalStorage, setLocaleToLocalStorage } from "@tm/utils"
import { AppConfig } from "../data/loadConfiguration"
import { LoginMatch } from "../login/matchLoginUrl"

export function getLanguageId(config: AppConfig, loginMatch: LoginMatch | undefined): string {
    // The language in the SSO url has top priority
    if (loginMatch?.lang) {
        setLocaleToLocalStorage(loginMatch.lang, loginMatch.lang)
        return loginMatch.lang
    }

    if (hasLocaleInLocalStorage()) {
        const storedLanguageId = getLanguageIdFromLocalStorage()!
        const { languagesWhitelist = [], languagesBlacklist = [] } = config

        if (
            (languagesWhitelist.length && languagesWhitelist.some((lang) => lang == storedLanguageId)) ||
            (!languagesWhitelist.length && !languagesBlacklist.some((lang) => lang == storedLanguageId))
        ) {
            return storedLanguageId
        }

        setLocaleToLocalStorage(config.locale, config.locale)
    }

    return config.locale
}
