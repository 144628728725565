import "./polyfill"
import "./vendors"
import "./shared"
import Morpheus, { Configuration } from "@tm/morpheus"
import transitions from "@tm/transitions"
import { initializePortal } from "./bootstrap"
import { bundles } from "./bundles"
import { ComponentError } from "./components/error/component-error"
import { layouts } from "./layouts"

/**
 * This is a namespace that contains documentation elements belonging to the Portal
 * domain.
 *
 * @namespace Portal
 */
initializePortal((config: Configuration) => new Morpheus(config, bundles, layouts, transitions, undefined, undefined, ComponentError))
