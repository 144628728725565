import { ComponentType } from "react"
import { LoginComponentProps } from "@tm/models"

export function LoginFactory(loginUrl: string): Promise<{ default: ComponentType<LoginComponentProps> }> {
    if (loginUrl.includes("dummy")) {
        return import(/* webpackChunkName: "logins/dummy" */ "@logins/dummy/src")
    }
    if (loginUrl.includes("esa")) {
        return import(/* webpackChunkName: "logins/esa" */ "@logins/esa/src")
    }
    if (loginUrl.includes("gmt")) {
        return import(/* webpackChunkName: "logins/gmt" */ "@logins/gmt/src")
    }
    if (loginUrl.includes("wm")) {
        return import(/* webpackChunkName: "logins/wm" */ "@logins/wm/src")
    }

    return import(/* webpackChunkName: "logins/default" */ "@logins/default/src")
}
