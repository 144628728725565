import { IRewrites } from "@portal/types"

/**
 * @memberof Portal
 * These entries are loaded into the NEXT DEV-TOOL (Chrome extension)
 * Also works without the extension -> to enable a rewrite just change the "false" to "true" for a rewrite
 * Example: ["/logins/default/index.js", "http://localhost:8078/index.js", true],
 */
export const REWRITES: IRewrites[] = [
    ["/styles/aagonline/styles.css", "/localStyles/styles/aagonline/styles.css", false],
    ["/styles/ac_catalogue/styles.css", "/localStyles/styles/ac_catalogue/styles.css", false],
    ["/styles/accat/styles.css", "/localStyles/styles/accat/styles.css", false],
    ["/styles/acr/styles.css", "/localStyles/styles/acr/styles.css", false],
    ["/styles/adecat/styles.css", "/localStyles/styles/adecat/styles.css", false],
    ["/styles/admaroc/styles.css", "/localStyles/styles/admaroc/styles.css", false],
    ["/styles/aldoc/images/favicon.ico", "/localStyles/styles/aldoc/images/favicon.ico", false],
    ["/styles/aldoc/images/logo.svg", "/localStyles/styles/aldoc/images/logo.svg", false],
    ["/styles/aldoc/styles.css", "/localStyles/styles/aldoc/styles.css", false],
    ["/styles/alm/styles.css", "/localStyles/styles/alm/styles.css", false],
    ["/styles/alpaweb/styles.css", "/localStyles/styles/alpaweb/styles.css", false],
    ["/styles/ams/styles.css", "/localStyles/styles/ams/styles.css", false],
    ["/styles/an24/styles.css", "/localStyles/styles/an24/styles.css", false],
    ["/styles/anag/styles.css", "/localStyles/styles/anag/styles.css", false],
    ["/styles/ara/styles.css", "/localStyles/styles/ara/styles.css", false],
    ["/styles/atevis/styles.css", "/localStyles/styles/atevis/styles.css", false],
    ["/styles/atm/styles.css", "/localStyles/styles/atm/styles.css", false],
    ["/styles/ats/styles.css", "/localStyles/styles/ats/styles.css", false],
    ["/styles/atu/styles.css", "/localStyles/styles/atu/styles.css", false],
    ["/styles/atu/icons.svg", "/localStyles/styles/atu/icons.svg", false],
    ["/styles/atw/styles.css", "/localStyles/styles/atw/styles.css", false],
    ["/styles/autodoc/styles.css", "/localStyles/styles/autodoc/styles.css", false],
    ["/styles/autonet-ro/styles.css", "/localStyles/styles/autonet-ro/styles.css", false],
    ["/styles/autonet-ro/icons.svg", "/localStyles/styles/autonet-ro/icons.svg", false],
    ["/styles/autoplus/styles.css", "/localStyles/styles/autoplus/styles.css", false],
    ["/styles/autosportshop/styles.css", "/localStyles/styles/autosportshop/styles.css", false],
    ["/styles/autovefurinn/styles.css", "/localStyles/styles/autovefurinn/styles.css", false],
    ["/styles/avag/styles.css", "/localStyles/styles/avag/styles.css", false],
    ["/styles/avs/styles.css", "/localStyles/styles/avs/styles.css", false],
    ["/styles/berica/styles.css", "/localStyles/styles/berica/styles.css", false],
    ["/styles/bilanaust/styles.css", "/localStyles/styles/bilanaust/styles.css", false],
    ["/styles/birner/styles.css", "/localStyles/styles/birner/styles.css", false],
    ["/styles/birner/icons.svg", "/localStyles/styles/birner/icons.svg", false],
    ["/styles/bm/icons.svg", "/localStyles/styles/bm/icons.svg", false],
    ["/styles/bmc/icons.svg", "/localStyles/styles/bmc/icons.svg", false],
    ["/styles/bmc/styles.css", "/localStyles/styles/bmc/styles.css", false],
    ["/styles/boschbra/styles.css", "/localStyles/styles/boschbra/styles.css", false],
    ["/styles/brera/styles.css", "/localStyles/styles/brera/styles.css", false],
    ["/styles/car/images/favicon.ico", "/localStyles/styles/car/images/favicon.ico", false],
    ["/styles/car/images/logo.svg", "/localStyles/styles/car/images/logo.svg", false],
    ["/styles/car/styles.css", "/localStyles/styles/car/styles.css", false],
    ["/styles/catalogo/icons.svg", "/localStyles/styles/catalogo/icons.svg", false],
    ["/styles/catalogo/styles.css", "/localStyles/styles/catalogo/styles.css", false],
    ["/styles/catalogo1/styles.css", "/localStyles/styles/catalogo1/styles.css", false],
    ["/styles/cdgroup/styles.css", "/localStyles/styles/cdgroup/styles.css", false],
    ["/styles/celpel/styles.css", "/localStyles/styles/celpel/styles.css", false],
    ["/styles/cgacat/styles.css", "/localStyles/styles/cgacat/styles.css", false],
    ["/styles/ciak/styles.css", "/localStyles/styles/ciak/styles.css", false],
    ["/styles/cmz/styles.css", "/localStyles/styles/cmz/styles.css", false],
    ["/styles/contitrade/icons.svg", "/localStyles/styles/contitrade/icons.svg", false],
    ["/styles/contitrade/styles.css", "/localStyles/styles/contitrade/styles.css", false],
    ["/styles/coparts/styles.css", "/localStyles/styles/coparts/styles.css", false],
    ["/styles/coparts/icons.svg", "/localStyles/styles/coparts/icons.svg", false],
    ["/styles/covalux/styles.css", "/localStyles/styles/covalux/styles.css", false],
    ["/styles/create-business/icons.svg", "/localStyles/styles/create-business/icons.svg", false],
    ["/styles/create-business/images/favicon.png", "/localStyles/styles/create-business/images/favicon.png", false],
    ["/styles/create-business/images/logo-blue.png", "/localStyles/styles/create-business/images/logo-blue.png", false],
    ["/styles/create-business/images/logo-white.png", "/localStyles/styles/create-business/images/logo-white.png", false],
    ["/styles/create-business/styles.css", "/localStyles/styles/create-business/styles.css", false],
    ["/styles/default/icons.svg", "/localStyles/styles/default/icons.svg", false],
    ["/styles/default/styles.css", "/localStyles/styles/default/styles.css", false],
    ["/styles/elekat/icons.svg", "/localStyles/styles/elekat/icons.svg", false],
    ["/styles/elekat/images/favicon.ico", "/localStyles/styles/elekat/images/favicon.ico", false],
    ["/styles/elekat/images/logo.png", "/localStyles/styles/elekat/images/logo.png", false],
    ["/styles/elekat/styles.css", "/localStyles/styles/elekat/styles.css", false],
    ["/styles/esa/images/favicon.ico", "/localStyles/styles/esa/images/favicon.ico", false],
    ["/styles/esa/images/logo.svg", "/localStyles/styles/esa/images/logo.svg", false],
    ["/styles/esa/styles.css", "/localStyles/styles/esa/styles.css", false],
    ["/styles/efa/styles.css", "/localStyles/styles/efa/styles.css", false],
    ["/styles/firststop/styles.css", "/localStyles/styles/firststop/styles.css", false],
    ["/styles/firststopbelgium/styles.css", "/localStyles/styles/firststopbelgium/styles.css", false],
    ["/styles/fleetpartner/styles.css", "/localStyles/styles/fleetpartner/styles.css", false],
    ["/styles/forauto/styles.css", "/localStyles/styles/forauto/styles.css", false],
    ["/styles/ft-parts/styles.css", "/localStyles/styles/ft-parts/styles.css", false],
    ["/styles/gag/styles.css", "/localStyles/styles/gag/styles.css", false],
    ["/styles/gemokat/styles.css", "/localStyles/styles/gemokat/styles.css", false],
    ["/styles/gmt/styles.css", "/localStyles/styles/gmt/styles.css", false],
    ["/styles/goehrum/images/favicon.ico", "/localStyles/styles/goehrum/images/favicon.ico", false],
    ["/styles/goehrum/icons.svg", "/localStyles/styles/goehrum/icons.svg", false],
    ["/styles/goehrum/images/logo.svg", "/localStyles/styles/goehrum/images/logo.svg", false],
    ["/styles/goehrum/styles.css", "/localStyles/styles/goehrum/styles.css", false],
    ["/styles/gramkow/styles.css", "/localStyles/styles/gramkow/styles.css", false],
    ["/styles/halla/styles.css", "/localStyles/styles/halla/styles.css", false],
    ["/styles/hansamobil/styles.css", "/localStyles/styles/hansamobil/styles.css", false],
    ["/styles/hartje/styles.css", "/localStyles/styles/hartje/styles.css", false],
    ["/styles/hartje/icons.svg", "/localStyles/styles/hartje/icons.svg", false],
    ["/styles/heil/images/favicon.ico", "/localStyles/styles/heil/images/favicon.ico", false],
    ["/styles/heil/images/logo.svg", "/localStyles/styles/heil/images/logo.svg", false],
    ["/styles/heil/styles.css", "/localStyles/styles/heil/styles.css", false],
    ["/styles/heil/icons.svg", "/localStyles/styles/heil/icons.svg", false],
    ["/styles/hlgroup/styles.css", "/localStyles/styles/hlgroup/styles.css", false],
    ["/styles/hostettler/images/favicon.ico", "/localStyles/styles/hostettler/images/favicon.ico", false],
    ["/styles/hostettler/images/logo.svg", "/localStyles/styles/hostettler/images/logo.svg", false],
    ["/styles/hostettler/styles.css", "/localStyles/styles/hostettler/styles.css", false],
    ["/styles/iapherman/styles.css", "/localStyles/styles/iapherman/styles.css", false],
    ["/styles/iaponiki/images/favicon.ico", "/localStyles/styles/iaponiki/images/favicon.ico", false],
    ["/styles/iaponiki/images/iaponiki-logo-weiss.png", "/localStyles/styles/iaponiki/images/iaponiki-logo-weiss.png", false],
    ["/styles/iaponiki/images/nextbg.png", "/localStyles/styles/iaponiki/images/nextbg.png", false],
    ["/styles/iaponiki/styles.css", "/localStyles/styles/iaponiki/styles.css", false],
    ["/styles/iaponiki/icons.svg", "/localStyles/styles/iaponiki/icons.svg", false],
    ["/styles/infopro/styles.css", "/localStyles/styles/infopro/styles.css", false],
    ["/styles/infoproaag/styles.css", "/localStyles/styles/infoproaag/styles.css", false],
    ["/styles/intertruck/styles.css", "/localStyles/styles/intertruck/styles.css", false],
    ["/styles/ira/styles.css", "/localStyles/styles/ira/styles.css", false],
    ["/styles/jaquet/styles.css", "/localStyles/styles/jaquet/styles.css", false],
    ["/styles/jurprom/styles.css", "/localStyles/styles/jurprom/styles.css", false],
    ["/styles/karabassis/styles.css", "/localStyles/styles/karabassis/styles.css", false],
    ["/styles/kastner/images/favicon.ico", "/localStyles/styles/kastner/images/favicon.ico", false],
    ["/styles/kastner/images/logo.svg", "/localStyles/styles/kastner/images/logo.svg", false],
    ["/styles/kastner/styles.css", "/localStyles/styles/kastner/styles.css", false],
    ["/styles/kd/styles.css", "/localStyles/styles/kd/styles.css", false],
    ["/styles/kfzteilshop/styles.css", "/localStyles/styles/kfzteilshop/styles.css", false],
    ["/styles/kgk-estonia/styles.css", "/localStyles/styles/kgk-estonia/styles.css", false],
    ["/styles/kirilowitsch/styles.css", "/localStyles/styles/kirilowitsch/styles.css", false],
    ["/styles/klein/images/favicon.ico", "/localStyles/styles/klein/images/favicon.ico", false],
    ["/styles/klein/images/logo-negativ-lang.svg", "/localStyles/styles/klein/images/logo-negativ-lang.svg", false],
    ["/styles/klein/styles.css", "/localStyles/styles/klein/styles.css", false],
    ["/styles/klostermann/icons.svg", "/localStyles/styles/klostermann/icons.svg", false],
    ["/styles/klostermann/styles.css", "/localStyles/styles/klostermann/styles.css", false],
    ["/styles/kosser/styles.css", "/localStyles/styles/kosser/styles.css", false],
    ["/styles/kpro-online-platform/styles.css", "/localStyles/styles/kpro-online-platform/styles.css", false],
    ["/styles/kraft/images/favicon.ico", "/localStyles/styles/kraft/images/favicon.ico", false],
    ["/styles/kraft/images/logo-negativ-lang.svg", "/localStyles/styles/kraft/images/logo-negativ-lang.svg", false],
    ["/styles/kraft/styles.css", "/localStyles/styles/kraft/styles.css", false],
    ["/styles/kraft/icons.svg", "/localStyles/styles/kraft/icons.svg", false],
    ["/styles/krautlipt/styles.css", "/localStyles/styles/krautlipt/styles.css", false],
    ["/styles/kristom/styles.css", "/localStyles/styles/kristom/styles.css", false],
    ["/styles/lkq/styles.css", "/localStyles/styles/lkq/styles.css", false],
    ["/styles/lkq/icons.svg", "/localStyles/styles/lkq/icons.svg", false],
    ["/styles/lkqbe/styles.css", "/localStyles/styles/lkqbe/styles.css", false],
    ["/styles/lkqeurope/styles.css", "/localStyles/styles/lkqeurope/styles.css", false],
    ["/styles/logicat/styles.css", "/localStyles/styles/logicat/styles.css", false],
    ["/styles/maccdoc/styles.css", "/localStyles/styles/maccdoc/styles.css", false],
    ["/styles/matthies/styles.css", "/localStyles/styles/matthies/styles.css", false],
    ["/styles/matthies2/styles.css", "/localStyles/styles/matthies2/styles.css", false],
    ["/styles/megaautoparts/styles.css", "/localStyles/styles/megaautoparts/styles.css", false],
    ["/styles/meganextcat/styles.css", "/localStyles/styles/meganextcat/styles.css", false],
    ["/styles/motocat/styles.css", "/localStyles/styles/motocat/styles.css", false],
    ["/styles/mpd/styles.css", "/localStyles/styles/mpd/styles.css", false],
    ["/styles/navcat/styles.css", "/localStyles/styles/navcat/styles.css", false],
    ["/styles/navcat/icons.svg", "/localStyles/styles/navcat/icons.svg", false],
    ["/styles/neimcke/styles.css", "/localStyles/styles/neimcke/styles.css", false],
    ["/styles/neimcke/icons.svg", "/localStyles/styles/neimcke/icons.svg", false],
    ["/styles/nentwig/styles.css", "/localStyles/styles/nentwig/styles.css", false],
    ["/styles/nmparts/styles.css", "/localStyles/styles/nmparts/styles.css", false],
    ["/styles/nuic/styles.css", "/localStyles/styles/nuic/styles.css", false],
    ["/styles/paconcorde/styles.css", "/localStyles/styles/paconcorde/styles.css", false],
    ["/styles/parts4friends/styles.css", "/localStyles/styles/parts4friends/styles.css", false],
    ["/styles/parts4you/styles.css", "/localStyles/styles/parts4you/styles.css", false],
    ["/styles/politzconnect/styles.css", "/localStyles/styles/politzconnect/styles.css", false],
    ["/styles/prime-autoteile/icons.svg", "/localStyles/styles/prime-autoteile/icons.svg", false],
    ["/styles/prime-autoteile/styles.css", "/localStyles/styles/prime-autoteile/styles.css", false],
    ["/styles/promofficina/icons.svg", "/localStyles/styles/promofficina/icons.svg", false],
    ["/styles/promofficina/styles.css", "/localStyles/styles/promofficina/styles.css", false],
    ["/styles/pv/icons.svg", "/localStyles/styles/pv/icons.svg", false],
    ["/styles/pv/images/alldata-logo.svg", "/localStyles/styles/pv/images/alldata-logo.png", false],
    ["/styles/pv/images/ellipsis.svg", "/localStyles/styles/pv/images/ellipsis.svg", false],
    ["/styles/pv/images/haynespro-logo.svg", "/localStyles/styles/pv/images/haynespro-logo.svg", false],
    ["/styles/pv/styles.css", "/localStyles/styles/pv/styles.css", false],
    ["/styles/rag/styles.css", "/localStyles/styles/rag/styles.css", false],
    ["/styles/reiff-sued/styles.css", "/localStyles/styles/reiff-sued/styles.css", false],
    ["/styles/renault/styles.css", "/localStyles/styles/renault/styles.css", false],
    ["/styles/rete1one/styles.css", "/localStyles/styles/rete1one/styles.css", false],
    ["/styles/repmaster/styles.css", "/localStyles/styles/repmaster/styles.css", false],
    ["/styles/repmaster/icons.svg", "/localStyles/styles/repmaster/icons.svg", false],
    ["/styles/romnes/styles.css", "/localStyles/styles/romnes/styles.css", false],
    ["/styles/sadi/styles.css", "/localStyles/styles/sadi/styles.css", false],
    ["/styles/saltonext/styles.css", "/localStyles/styles/saltonext/styles.css", false],
    ["/styles/sb-cat/styles.css", "/localStyles/styles/sb-cat/styles.css", false],
    ["/styles/schmettau-fuchs/styles.css", "/localStyles/styles/schmettau-fuchs/styles.css", false],
    ["/styles/schmettau-fuchs/icons.svg", "/localStyles/styles/schmettau-fuchs/icons.svg", false],
    ["/styles/simimpex/styles.css", "/localStyles/styles/simimpex/styles.css", false],
    ["/styles/stg/icons.svg", "/localStyles/styles/stg/icons.svg", false],
    ["/styles/stg/images/leistungsschau.png", "/localStyles/styles/stg/images/leistungsschau.png", false],
    ["/styles/stg/styles.css", "/localStyles/styles/stg/styles.css", false],
    ["/styles/teccat/images/favicon.ico", "/localStyles/styles/teccat/images/favicon.ico", false],
    ["/styles/teccat/images/logo.svg", "/localStyles/styles/teccat/images/logo.svg", false],
    ["/styles/teccat/styles.css", "/localStyles/styles/teccat/styles.css", false],
    ["/styles/teccat-industry/styles.css", "/localStyles/styles/teccat-industry/styles.css", false],
    ["/styles/teccat_standalone/styles.css", "/localStyles/styles/teccat_standalone/styles.css", false],
    ["/styles/teccat-uk/styles.css", "/localStyles/styles/teccat-uk/styles.css", false],
    ["/styles/tech-co/styles.css", "/localStyles/styles/tech-co/styles.css", false],
    ["/styles/tech-co/images/favicon.ico", "/localStyles/styles/tech-co/images/favicon.ico", false],
    ["/styles/tech-co/images/logo.svg", "/localStyles/styles/tech-co/images/logo.svg", false],
    ["/styles/tecparts/styles.css", "/localStyles/styles/tecparts/styles.css", false],
    ["/styles/teileprofis/styles.css", "/localStyles/styles/teileprofis/styles.css", false],
    ["/styles/temp1/styles.css", "/localStyles/styles/temp1/styles.css", false],
    ["/styles/temp1/styles.css", "/localStyles/styles/temp1/styles.css", false],
    ["/styles/texkat/images/favicon.ico", "/localStyles/styles/texkat/images/favicon.ico", false],
    ["/styles/texkat/images/logo-sw@2x.png", "/localStyles/styles/texkat/images/logo-sw@2x.png", false],
    ["/styles/texkat/styles.css", "/localStyles/styles/texkat/styles.css", false],
    ["/styles/tokic/icons.svg", "/localStyles/styles/tokic/icons.svg", false],
    ["/styles/tokic/images/favicon.ico", "/localStyles/styles/tokic/images/favicon.ico", false],
    ["/styles/tokic/images/logo.svg", "/localStyles/styles/tokic/images/logo.svg", false],
    ["/styles/tokic/styles.css", "/localStyles/styles/tokic/styles.css", false],
    ["/styles/topautoteile/styles.css", "/localStyles/styles/topautoteile/styles.css", false],
    ["/styles/versaparts/styles.css", "/localStyles/styles/versaparts/styles.css", false],
    ["/styles/wavetech/styles.css", "/localStyles/styles/wavetech/styles.css", false],
    ["/styles/wijlhuizen/styles.css", "/localStyles/styles/wijlhuizen/styles.css", false],
    ["/styles/wm/styles.css", "/localStyles/styles/wm/styles.css", false],
    ["/styles/wm/icons.svg", "/localStyles/styles/wm/icons.svg", false],
    ["/styles/womplus/styles.css", "/localStyles/styles/womplus/styles.css", false],
    ["/styles/womplus/images/favicon.svg", "/localStyles/styles/womplus/images/favicon.ico", false],
    ["/styles/womplus/images/logo.png", "/localStyles/styles/womplus/images/logo.png", false],
    ["/styles/womplus/icons.svg", "/localStyles/styles/womplus/icons.svg", false],
]
