import { ajaxAuth } from "@tm/utils"
import { WebserviceUrl } from "../../webserviceUrls"
import { GetLoginShareKeyResponse } from "./models"

export function getLoginShareKey() {
    const url = `${WebserviceUrl.Authority}/GetLoginShareKey`
    const body = {}

    return ajaxAuth<GetLoginShareKeyResponse>({ url, body, method: "GET" }).then((data) => {
        if (data?.shareKey) {
            return data.shareKey
        }

        throw new Error()
    })
}
