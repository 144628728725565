import { ajaxAuth } from "@tm/utils"
import { GetActivatableModulesResponse } from "@tm/models"
import { WebserviceUrl } from "../../../webserviceUrls"

export function getActivatableModules(): Promise<GetActivatableModulesResponse | undefined> {
    const url = `${WebserviceUrl.AuthorityModulStore}/GetActivatableModules`
    const body = {}

    return ajaxAuth({ url, body })
}
