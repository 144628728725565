import { ajaxAuth, generatePKCECodeVerifier, getOAuthClient, getStoredOAuthData, storeOAuthData, uniqueId } from "@tm/utils"
import { History } from "history"
import { WebserviceUrl } from "@tm/data/webserviceUrls"
import { AppConfig } from "../data/loadConfiguration"
import { LoginMatch, matchLoginUrl } from "./matchLoginUrl"
import { trySingleSignOnAndRedirect } from "./trySingleSignOn"

/**
 * This function is used to handle the external authentication.
 *
 * When the external authentication is enabled in the app config and we are currently on the login page (not the login error page),
 * it will be checked if the authorization code is present in the URL and if not it will redirect to the external authentication page.
 *
 * Implemented for Bosch SingleKey-ID login - see NEXT-28383.
 *
 * @returns A boolean indicating if the external authentication was handled (either redirect or automatic login) and further actions during the bootstrap initialization should be skipped.
 */
export async function handleExternalAuthentication(
    appConfig: AppConfig,
    history: History<unknown>,
    languageId: string,
    catalog: string
): Promise<boolean> {
    const { externalAuthenticationOAuth } = appConfig.login ?? {}
    if (!externalAuthenticationOAuth) {
        return false
    }

    const loginMatch = matchLoginUrl(history, false)
    if (!loginMatch) {
        return false
    }

    const client = getOAuthClient(externalAuthenticationOAuth, appConfig.params.env === "prod")

    const { authorizationCode } = loginMatch
    const redirectUri = `${window.location.origin}/login/${catalog}`

    if (!authorizationCode) {
        // If no authorization code is present yet, redirect to external authentication page.
        // The authorization code is present if the user has already logged in externally and the page redirected back to NEXT.

        storeLoginMatch(loginMatch)

        const state = uniqueId()
        const codeVerifier = await generatePKCECodeVerifier()
        storeOAuthData({ state, redirectUri, codeVerifier })

        document.location.href = await client.authorizationCode.getAuthorizeUri({
            redirectUri,
            state,
            codeVerifier,
            scope: externalAuthenticationOAuth.scope,
            extraParams: externalAuthenticationOAuth.extraParams,
        })

        return true
    }

    // If the authorization code is present, get the login match from before the redirect and try if single sign-on is possible.

    try {
        const { codeVerifier, state } = getStoredOAuthData() ?? {}
        if (!codeVerifier || !state) {
            throw new Error("Missing code verifier or state")
        }

        const { code } = client.authorizationCode.validateResponse(document.location.href, { state }) // Will throw when the state is not matching
        storeOAuthData({ authorizationCode: code, redirectUri, codeVerifier })

        const storedLoginMatch = getStoredLoginMatch()
        if (storedLoginMatch && trySingleSignOnAndRedirect(storedLoginMatch, languageId, catalog, appConfig.params.disableAnalytics)) {
            // If we land here the single sign-on was successful (meaning a token was present in the original request)
            // and we have to store the authorization code and the redirect URL for this session.

            await ajaxAuth({
                url: `${WebserviceUrl.AuthorityThirdPartyLogin}/SetBoschSSOTokenToSession`,
                method: "POST",
                body: {
                    authorizationCode: code,
                    redirectUri,
                    codeVerifier,
                },
            })

            return true
        }
    } catch {
        history.replace(`/login-error/${catalog}`)
    }

    return false
}

function getServerUrl(config: NonNullable<AppConfig["login"]["externalAuthenticationOAuth"]>, isProd: boolean): string {
    if (typeof config.server === "string") {
        return config.server
    }
    if (config.server.overwrite && !config.server.overwrite.includes("Placeholder not found")) {
        return config.server.overwrite
    }
    if (!isProd) {
        return config.server.staging
    }

    return config.server.production
}

function storeLoginMatch(loginMatch: LoginMatch) {
    sessionStorage.setItem("loginMatch", JSON.stringify(loginMatch))
}

function getStoredLoginMatch(): LoginMatch | undefined {
    const storedLoginMatch = sessionStorage.getItem("loginMatch")

    if (storedLoginMatch) {
        return JSON.parse(storedLoginMatch)
    }
}
