import { ajaxAuth } from "@tm/utils"
import { SensorItemsRequest, SensorItemsResponse } from "@tm/models"
import { mapSensorArticle } from "./mapper"
import { WebserviceUrl } from "../../webserviceUrls"

export function loadSensorItems(request?: SensorItemsRequest): Promise<SensorItemsResponse> {
    const url = `${WebserviceUrl.CatalogArticles}/Articles`
    const body = request

    return ajaxAuth({ url, body }).then((response) => {
        if (response?.articles?.length && response?.articleListCount) {
            return {
                articles: response.articles.map(mapSensorArticle),
                hasNextPage: response.hasNextPage || false,
            }
        }
        return {
            articles: [],
            hasNextPage: false,
        }
    })
}
