import { Box, Typography } from "@tm/components"

type Props = {
    title: string
    text: string
}

export function Message({ title, text }: Props) {
    return (
        <Box textAlign="center">
            <Typography variant="h1" mb={4}>
                {title}
            </Typography>
            <Typography whiteSpace="pre-wrap">{text}</Typography>
        </Box>
    )
}
