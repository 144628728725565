import { Box, styled } from "@tm/components"
import { AppConfig, OAuthConfig, OAuthTokenIds } from "@tm/models"
import React from "react"
import { AuthorizeFlowComponent } from "./AuthorizeFlowComponent"
import { Message } from "./Message"
import { ValidateFlowComponent } from "./ValidateFlowComponent"

const Wrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(4),
}))

type Props = {
    tokenId: OAuthTokenIds
    config: AppConfig["params"]["oAuthConfiguration"]
    isProd: boolean
}

export function OAuthComponent({ tokenId, config, isProd }: Props) {
    const tokenConfig = config?.[tokenId]

    if (!tokenConfig) {
        return (
            <Wrapper>
                <Message title="You shouldn't be here. 😯" text="You can close this window, there is nothing to see here." />
            </Wrapper>
        )
    }

    function renderFlow(config: OAuthConfig) {
        const queryParams = new URLSearchParams(window.location.search)
        const authorizationCode = queryParams.get("code")

        // If no authorization code is present yet, redirect to external authentication page.
        // The authorization code is present if the user has already logged in externally and the page redirected back to NEXT.
        if (!authorizationCode) {
            return <AuthorizeFlowComponent config={config} isProd={isProd} />
        }

        return <ValidateFlowComponent tokenId={tokenId} config={config} isProd={isProd} />
    }

    return <Wrapper>{renderFlow(tokenConfig)}</Wrapper>
}
