import { IBundle } from "@tm/morpheus"

import ApiBundle from "@bundles/api"
import BasketBundle from "@bundles/basket"
import CarmunicationBundle from "@bundles/carmunication"
import CisBundle from "@bundles/cis"
import CompilationsBundle from "@bundles/compilations"
import CrmBundle from "@bundles/crm"
import DamageCalculationAswegasBundle from "@bundles/damage-calculation-aswegas"
import DamageCalculationDatBundle from "@bundles/damage-calculation-dat"
import DamageCalculationEurotaxBundle from "@bundles/damage-calculation-eurotax"
import DamageCalculationGlassBundle from "@bundles/damage-calculation-glass"
import DmsBundle from "@bundles/dms"
import DutchOrderPlatesBundle from "@bundles/dutch-order-plates"
import DrivemotiveBundle from "@bundles/drivemotive"
import EdsBundle from "@bundles/eds"
import ErpBundle from "@bundles/erp"
import EurotaxBundle from "@bundles/eurotax"
import FastCalculatorBundle from "@bundles/fast-calculator"
import FastDtcBundle from "@bundles/fast-dtc"
import FastServiceBundle from "@bundles/fast-service"
import FeedbackBundle from "@bundles/feedback"
import MemoToolBundle from "@bundles/memo-tool"
import MiscBundle from "@bundles/misc"
import MvcBundle from "@bundles/mvc"
import NotificationsBundle from "@bundles/notifications"
import OffersBundle from "@bundles/offers"
import PartsBundle from "@bundles/parts"
import RepairTimesBundle from "@bundles/repair-times"
import SettingsBundle from "@bundles/settings"
import SindriBundle from "@bundles/sindri"
import PartslifeBundle from "@bundles/partslife"
import StandaloneBundle from "@bundles/standalone"
import TelematicsBundle from "@bundles/telematics"
import TelesalesBundle from "@bundles/telesales"
import TyresBundle from "@bundles/tyres"
import VehicleBundle from "@bundles/vehicle"
import VehicleRecordsBundle from "@bundles/vehicle-records"
import VouchersBundle from "@bundles/vouchers"
import WheelsBundle from "@bundles/wheels"
import WorktaskBundle from "@bundles/worktask"
import VefilesBundle from "@bundles/vefiles"

// Trader bundles
// import TraderAutonetBundle from "@bundles/trader/autonet"
import TraderBirnerBundle from "@bundles/trader/birner"
import TraderWmBundle from "@bundles/trader/wm"

export const bundles: Record<string, IBundle> = {
    api: ApiBundle,
    basket: BasketBundle,
    carmunication: CarmunicationBundle,
    cis: CisBundle,
    compilations: CompilationsBundle,
    crm: CrmBundle,
    "dmg-aswegas": DamageCalculationAswegasBundle,
    "dmg-dat": DamageCalculationDatBundle,
    "dmg-eurotax": DamageCalculationEurotaxBundle,
    "dmg-glass": DamageCalculationGlassBundle,
    dms: DmsBundle,
    "dutch-order-plates": DutchOrderPlatesBundle,
    drivemotive: DrivemotiveBundle,
    eds: EdsBundle,
    erp: ErpBundle,
    eurotax: EurotaxBundle,
    "fast-calculator-next": FastCalculatorBundle,
    "fast-dtc": FastDtcBundle,
    "fast-service": FastServiceBundle,
    feedback: FeedbackBundle,
    "memo-tool": MemoToolBundle,
    misc: MiscBundle,
    mvc: MvcBundle,
    notifications: NotificationsBundle,
    offers: OffersBundle,
    parts: PartsBundle,
    "repair-times": RepairTimesBundle,
    settings: SettingsBundle,
    sindri: SindriBundle,
    partslife: PartslifeBundle,
    standalone: StandaloneBundle,
    telematics: TelematicsBundle,
    telesales: TelesalesBundle,
    tyres: TyresBundle,
    vehicle: VehicleBundle,
    "vehicle-records": VehicleRecordsBundle,
    vouchers: VouchersBundle,
    wheels: WheelsBundle,
    worktask: WorktaskBundle,
    vefiles: VefilesBundle,

    // Trader bundles
    // "autonet": TraderAutonetBundle, // TODO: not in use anymore - check if it can be removed permanantly
    birner: TraderBirnerBundle,
    wm: TraderWmBundle,
}

/**
 * The following was just a try to load the bundles only if they're needed. The problem was, that the cross imports
 * between the bundles led for aexample to a huge bzasket file, because of the imports  from the parts bundle. The parts
 * file was in this example not existent.
 */
// export const bundlePromises = {
//     api: import(/* webpackChunkName: "bundles/api" */ "@bundles/api"),
//     basket: import(/* webpackChunkName: "bundles/basket" */ "@bundles/basket"),
//     carmunication: import(/* webpackChunkName: "bundles/carmunication" */ "@bundles/carmunication"),
//     cis: import(/* webpackChunkName: "bundles/cis" */ "@bundles/cis"),
//     compilations: import(/* webpackChunkName: "bundles/compilations" */ "@bundles/compilations"),
//     crm: import(/* webpackChunkName: "bundles/crm" */ "@bundles/crm"),
//     "dmg-aswegas": import(/* webpackChunkName: "bundles/damage-calculation-aswegas" */ "@bundles/damage-calculation-aswegas"),
//     "dmg-dat": import(/* webpackChunkName: "bundles/damage-calculation-dat" */ "@bundles/damage-calculation-dat"),
//     "dmg-eurotax": import(/* webpackChunkName: "bundles/damage-calculation-eurotax" */ "@bundles/damage-calculation-eurotax"),
//     "dmg-glass": import(/* webpackChunkName: "bundles/damage-calculation-glass" */ "@bundles/damage-calculation-glass"),
//     dms: import(/* webpackChunkName: "bundles/dms" */ "@bundles/dms"),
//     "dutch-order-plates": import(/* webpackChunkName: "bundles/dutch-order-plates" */ "@bundles/dutch-order-plates"),
//     drivemotive: import(/* webpackChunkName: "bundles/drivemotive" */ "@bundles/drivemotive"),
//     eds: import(/* webpackChunkName: "bundles/eds" */ "@bundles/eds"),
//     erp: import(/* webpackChunkName: "bundles/erp" */ "@bundles/erp"),
//     eurotax: import(/* webpackChunkName: "bundles/eurotax" */ "@bundles/eurotax"),
//     "fast-calculator-next": import(/* webpackChunkName: "bundles/fast-calculator" */ "@bundles/fast-calculator"),
//     "fast-dtc": import(/* webpackChunkName: "bundles/fast-dtc" */ "@bundles/fast-dtc"),
//     "fast-service": import(/* webpackChunkName: "bundles/fast-service" */ "@bundles/fast-service"),
//     feedback: import(/* webpackChunkName: "bundles/feedback" */ "@bundles/feedback"),
//     "memo-tool": import(/* webpackChunkName: "bundles/memo-tool" */ "@bundles/memo-tool"),
//     misc: import(/* webpackChunkName: "bundles/misc" */ "@bundles/misc"),
//     mvc: import(/* webpackChunkName: "bundles/mvc" */ "@bundles/mvc"),
//     notifications: import(/* webpackChunkName: "bundles/notifications" */ "@bundles/notifications"),
//     offers: import(/* webpackChunkName: "bundles/offers" */ "@bundles/offers"),
//     parts: import(/* webpackChunkName: "bundles/parts" */ "@bundles/parts"),
//     "repair-times": import(/* webpackChunkName: "bundles/repair-times" */ "@bundles/repair-times"),
//     schedules: import(/* webpackChunkName: "bundles/schedules" */ "@bundles/schedules"),
//     settings: import(/* webpackChunkName: "bundles/settings" */ "@bundles/settings"),
//     sindri: import(/* webpackChunkName: "bundles/sindri" */ "@bundles/sindri"),
//     partslife: import(/* webpackChunkName: "bundles/partslife" */ "@bundles/partslife"),
//     standalone: import(/* webpackChunkName: "bundles/standalone" */ "@bundles/standalone"),
//     telematics: import(/* webpackChunkName: "bundles/telematics" */ "@bundles/telematics"),
//     telesales: import(/* webpackChunkName: "bundles/telesales" */ "@bundles/telesales"),
//     tyres: import(/* webpackChunkName: "bundles/tyres" */ "@bundles/tyres"),
//     vehicle: import(/* webpackChunkName: "bundles/vehicle" */ "@bundles/vehicle"),
//     "vehicle-records": import(/* webpackChunkName: "bundles/vehicle-records" */ "@bundles/vehicle-records"),
//     vouchers: import(/* webpackChunkName: "bundles/vouchers" */ "@bundles/vouchers"),
//     wheels: import(/* webpackChunkName: "bundles/wheels" */ "@bundles/wheels"),
//     worktask: import(/* webpackChunkName: "bundles/worktask" */ "@bundles/worktask"),
//     vefiles: import(/* webpackChunkName: "bundles/vefiles" */ "@bundles/vefiles"),

//     // Trader bundles
//     birner: import(/* webpackChunkName: "bundles/trader/birner" */ "@bundles/trader/birner"),
//     wm: import(/* webpackChunkName: "bundles/trader/wm" */ "@bundles/trader/wm"),
// }
