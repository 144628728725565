import { createLocalizationProvider, GetCatalogTextsFunction, LanguagesConfig, LocalizationConfig } from "@tm/localization"
import { ajax } from "@tm/utils"
import { AppConfig } from "../data/loadConfiguration"

export async function getLocalizationProvider(languageId: string, config: AppConfig, getCatalogTexts: GetCatalogTextsFunction) {
    const [localizationConfig, languagesConfig] = await getLocalizationConfigs(languageId, config)

    return createLocalizationProvider(
        localizationConfig,
        (locale: string) => ajax({ url: createLocaleUrl(locale) }) as any,
        languagesConfig,
        "/datelocales",
        getCatalogTexts
    )
}

async function getLocalizationConfigs(languageId: string, config: AppConfig): Promise<[LocalizationConfig, LanguagesConfig]> {
    let [localizationConfig = { language: "en", id: "4", translation: {} }, languagesConfig = {}] = await Promise.all([
        loadLocalizationConfig(languageId),
        loadLanguagesConfig(),
    ])

    const { languagesWhitelist = [], languagesBlacklist = [] } = config

    if (languagesWhitelist.length) {
        languagesConfig = Object.keys(languagesConfig)
            .filter((x) => languagesWhitelist.includes(x))
            .reduce((prev, key) => ({ ...prev, [key]: languagesConfig[key] }), {})
    } else if (languagesBlacklist.length) {
        languagesConfig = Object.keys(languagesConfig)
            .filter((x) => !languagesBlacklist.includes(x))
            .reduce((prev, key) => ({ ...prev, [key]: languagesConfig[key] }), {})
    }

    return [localizationConfig, languagesConfig]
}

async function loadLocalizationConfig(languageId: string): Promise<LocalizationConfig | undefined> {
    try {
        const config = await ajax<LocalizationConfig | string>({ url: createLocaleUrl(languageId) })

        if (typeof config === "object") {
            return config
        }

        throw new Error(`Failed to load LocalizationConfig "${languageId}.json" (containing text id mappings)`)
    } catch {
        if (languageId !== "4") {
            return loadLocalizationConfig("4") // If config content is not valid load English config as fallback
        }

        return undefined
    }
}

async function loadLanguagesConfig(): Promise<LanguagesConfig | undefined> {
    try {
        const config = await ajax<LanguagesConfig>({ url: createLocaleUrl("language") })

        if (typeof config === "object") {
            return config
        }

        throw new Error(`Failed to load LanguagesConfig "language.json" (containing language mappings)`)
    } catch {
        return undefined
    }
}

function createLocaleUrl(file: string): string {
    return `/locale/${file}.json`
}
