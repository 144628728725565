import { GetAuthTokenAnonymousRequest, GetAuthTokenResponse } from "@tm/models"
import { ajax, hasValidStoredLogin, removeStoredLogin } from "@tm/utils"
import { AppConfig } from "../data/loadConfiguration"
import { handleLoginSuccess } from "./handleLoginOutcome"
import { storeToken } from "./storeToken"

export async function handleAnonymousLogin(catalog: string, appConfig: AppConfig, languageId: string): Promise<boolean> {
    const {
        login: { authId, serviceUrl },
    } = appConfig

    if (!authId) {
        return false
    }

    // Check if we already have a valid stored toked, if so skip getting a new one.
    if (!hasValidStoredLogin()) {
        const params = new URLSearchParams(window.location.search)

        const request: GetAuthTokenAnonymousRequest = {
            authId: typeof authId === "object" && authId.default ? authId.default : (authId as string),
            languageId: parseInt(languageId) || undefined,
            debugApiKey: params.get("debugApiKey") ?? undefined,
            debugLoggingFlags: Number(params.get("debugLoggingFlags")) || undefined,
            loginInterface: "frontend",
        }

        const response = await getAuthTokenAnonymous(serviceUrl, request)

        if (response?.error || !response?.token) {
            removeStoredLogin()
            return false
        }

        storeToken(response.token, languageId)
    }

    handleLoginSuccess(catalog)

    return true
}

function getAuthTokenAnonymous(serviceUrl: string, request: GetAuthTokenAnonymousRequest) {
    return ajax<GetAuthTokenResponse>({
        url: `${serviceUrl}/GetAuthTokenByAuthId`,
        method: "POST",
        body: request,
    })
}
