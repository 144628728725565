import type { ModuleGroupId, ModuleId, UserContext } from "@tm/models"
import { useGetActivatableModules } from "./useGetActivatableModules"

export function useCheckDemoModuleActive(userContext: UserContext, moduleGroupId: ModuleGroupId | undefined, moduleId: ModuleId | undefined) {
    const valid = !!moduleGroupId && !!moduleId

    const activatableModulesLoadable = useGetActivatableModules(userContext, !valid)

    if (!valid) {
        return false
    }

    const demoModule = activatableModulesLoadable?.response?.moduleGroups
        ?.find((moduleGroup) => moduleGroup.id === moduleGroupId)
        ?.modules.find((module) => module.id === moduleId)

    return demoModule?.moduleOptions?.demo?.active ?? false
}
