import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { Button, MessageUpdateCheck } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { loadManifest } from "../bootstrap/data/loadManifest"

const MAX_RETRIES = 5
const CHECK_INTERVAL_MS = 30000

export function UpdateChecker() {
    const { translateText } = useLocalization()

    const [version, setVersion] = useState<string | undefined>()
    const [isUpdating, setIsUpdating] = useState(false)
    const [show, setShow] = useState(false)
    const [retryCount, setRetryCount] = useState(0)

    const handleError = useCallback(() => {
        setRetryCount((retryCount) => retryCount + 1)
    }, [])

    useEffect(() => {
        // Initially load manifest and log version to console
        loadManifest()
            .then((manifest) => {
                if (manifest.isUpdating) {
                    setIsUpdating(true)

                    if (!manifest.isQuietUpdate) {
                        setShow(true)
                    }
                }

                console.info(`Current NEXT version: ${manifest.version}`)
                setVersion(manifest.version)
                setWindowVersion(manifest.version)
            })
            .catch(handleError)
    }, [handleError])

    useEffect(() => {
        if (show) {
            return
        }

        if (retryCount >= MAX_RETRIES) {
            console.warn(`'manifest.json' failed to load for ${MAX_RETRIES} times. Stopped checking if app version has changed.`)
            return
        }

        // Refresh manifest every few seconds* and check if the version has changed
        const intervalId = window.setInterval(
            () => {
                if (document.visibilityState === "hidden") {
                    return
                }

                loadManifest()
                    .then((manifest) => {
                        setRetryCount(0) // Reset retry count on success

                        if (manifest.isUpdating) {
                            setIsUpdating(true)

                            if (!isUpdating && !manifest.isQuietUpdate) {
                                setShow(true)
                            }

                            return
                        }

                        setIsUpdating(false)

                        if (manifest.version != version) {
                            if (!manifest.isQuietUpdate) {
                                setShow(true)
                            }

                            console.info(`Updated NEXT version available: ${manifest.version}`)
                            setVersion(manifest.version)
                            setWindowVersion(manifest.version)
                            channel("GLOBAL").publish("ERP/CLEAR_CACHE", {})
                        }
                    })
                    .catch(handleError)
            },
            CHECK_INTERVAL_MS * (retryCount + 1)
        ) // *: increase the interval with every retry

        return () => window.clearInterval(intervalId)
    }, [show, isUpdating, retryCount, version, handleError])

    const reloadPage = useCallback(() => location.reload(), [])
    const hideMessage = useCallback(() => setShow(false), [])

    if (!show) {
        return null
    }

    const container = document.getElementById("update-check")

    if (!container) {
        return null
    }

    if (isUpdating) {
        return createPortal(
            <MessageUpdateCheck
                title={translateText(12434)}
                message={translateText(12435)}
                onCancel={hideMessage}
                buttons={
                    <Button layout={["holo"]} onClick={hideMessage}>
                        {translateText(316)}
                    </Button>
                }
            />,
            container
        )
    }

    return createPortal(
        <MessageUpdateCheck
            title={translateText(1598)}
            message={translateText(1247)}
            onCancel={hideMessage}
            buttons={
                <Button icon="synchronize" layout={["holo"]} onClick={reloadPage}>
                    {translateText(276)}
                </Button>
            }
        />,
        container
    )
}

// Needed so the version can be displayed in the user settings
function setWindowVersion(version: string) {
    ;(window as any).__version = version
}
