import { useCallback } from "react"
import { useMutation } from "react-query"
import { GetSharedAccessKeyRequest } from "../models"
import { ERIKContentService } from "../.."

export function useGetSharedAccessKey() {
    const { isLoading, mutateAsync } = useMutation(ERIKContentService.getSharedAccessKey)

    const getSharedAccessKey = useCallback(
        (ids: string[], expireDays: number) => {
            const request: GetSharedAccessKeyRequest = {
                ids,
                expireDays,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { isLoading, getSharedAccessKey }
}
