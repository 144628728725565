export const mockParams: Record<string, Array<any>> = {
    ActionsBaseUrl: ["http://actions1.dvse.de"],
    AuthorityHost: ["/data"],
    "Deployment.Environment": ["dev"],
    FastCheckUrl: ["https://fastcheck.dvse.de"],
    FastUpApiBaseUrl: ["https://tmfastuptest.azurewebsites.net"],
    LoginsBaseUrl: ["/logins"],
    ModuleMode: ["2"],
    MvcBaseUrl: ["https://web1.dvse.de/Sub"],
    PartsSearchTreeIconsBaseUrl: ["//media.carparts-cat.com/webdata1/imageData/theme/21"],
    ServicesBaseUrl: ["/data"],
    StylesBaseUrl: ["/styles"],
    TMChatBaseUrl: ["https://topmotivechattest.azurewebsites.net"],
    TMTransactionsBaseUrl: ["https://tmtransaction.dvse.de"],
    TransitionsBaseUrl: ["/transitions"],
    VinQueryModuleCosts: ["2€"],
    "Wm.CatalogsUrl": ["https://www.wm.de/files/st_mediathek_wmkatplus"],
    showImportFileButton: ["1"],
    "Telematics.SindriAuthUrl": ["https://sindri-validation-vv-v2-2-customer-api-domain.auth.eu-central-1.amazoncognito.com"],
    "FastService.WaveUploadUrl": ["https://cs-dev.erik.cbwg.local"],
    "Wheels.3DImages": ["https://staticextdata.carparts-cat.com/TMW/Graphic/3d/resources/"],
}
