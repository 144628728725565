import { handleLoginSuccess, handleTmaLogin } from "./handleLoginOutcome"
import { LoginMatch } from "./matchLoginUrl"
import { storeToken } from "./storeToken"

/** *
 * @description aka loginH(idden)
 */
export function trySingleSignOnAndRedirect(loginMatch: LoginMatch, languageId: string, catalog: string, disableAnalytics: boolean): boolean {
    if (!loginMatch.token) {
        return false
    }

    storeToken(loginMatch.token, languageId)

    handleLoginSuccess(catalog)
    handleTmaLogin("hidden", disableAnalytics).finally(() => {
        redirect(loginMatch)
    })

    return true
}

function redirect(loginMatch: LoginMatch) {
    const redirectUrl = loginMatch.redirect

    if (redirectUrl) {
        // if there's a redirect url, don't show any external homepage
        if (redirectUrl) {
            sessionStorage.setItem("HOMEPAGE_SHOWN", "1")
        }

        location.replace(`/${redirectUrl}`)
        return true
    }

    location.replace("/")
    return true
}
