import { Component, createRef, PropsWithChildren } from "react"
import Morpheus from "@tm/morpheus"
import { Box, Button, Icon, styled } from "@tm/components"
import { bindSpecialReactMethods, EventListenerManager } from "@tm/utils"
import { channel } from "@tm/models"

type ModalProps = PropsWithChildren<{
    name: string
    opened?: boolean
}>

type ModalState = {
    opened?: boolean
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => !["opened"].includes(prop as string),
})((opened: ModalState) => {
    return {
        position: "fixed",
        top: "50%",
        left: "0",
        zIndex: "100",
        transform: "translateX(-1em)",
        visibility: opened ? "unset" : "hidden",
    }
})

const modals = document.querySelector("#modals")
class Modal extends Component<ModalProps, ModalState> {
    private unregisterOutsideClick?: () => void

    private modalRef = createRef<HTMLDivElement>()

    private modalParentRef = createRef<HTMLDivElement>()

    constructor(props: ModalProps) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = { opened: props.opened }
    }

    removeChildren() {
        if (modals?.children) {
            const { children } = modals

            if (children.length > 0) {
                const currentModal = children[0].getElementsByClassName("modal")
                const modal = currentModal.length && currentModal[0]

                if (modal && this.modalRef && modal != this.modalRef.current) {
                    children[0].remove() // remove if it's not my modal
                }
            }
        }
    }

    componentDidMount() {
        if (this.state.opened) {
            this.removeChildren()
            this.registerOutsideClick()
            this.toggleHtmlClassNoScroll(true)
            this.modalRef.current?.focus()
        }
    }

    componentDidUpdate(_prevProps: ModalProps, prevState: ModalState) {
        this.state.opened && !prevState.opened && this.modalParentRef.current?.focus()
    }

    componentWillUnmount() {
        this.unregisterOutsideClick && this.unregisterOutsideClick()
        this.toggleHtmlClassNoScroll(false)
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    public toggle(action: "OPEN" | "CLOSE") {
        const open = action === "OPEN"

        if (this.state.opened === open) {
            return
        }

        this.setState({ opened: open }, () => {
            const { opened } = this.state
            const { name } = this.props

            this.toggleHtmlClassNoScroll(opened!)

            if (opened) {
                setTimeout(() => {
                    channel("GLOBAL").publish("MODAL/OPENED", { name })
                }, 100)

                this.removeChildren()
                this.registerOutsideClick()

                return
            }

            setTimeout(() => {
                channel("GLOBAL").publish("MODAL/CLOSED", { name })
            }, 100)

            this.unregisterOutsideClick && this.unregisterOutsideClick()
        })
    }

    private registerOutsideClick() {
        if (this.modalRef.current) {
            this.unregisterOutsideClick = EventListenerManager.registerEventListener(
                "outsideClick",
                this.modalRef.current,
                (e) => this.handleClose(e),
                false
            )
        }
    }

    private toggleHtmlClassNoScroll(open: boolean) {
        const htmlElement = document.querySelector("html")

        if (htmlElement) {
            htmlElement.classList[open ? "add" : "remove"]("noscroll")
        }
    }

    private handleClose(event?: Event) {
        // The check prevents the modal from being closed when another modal is clicked
        // The check on the event should be there because otherwise "undefined" is compared with the "modalParentRef"
        if (event && event.target !== this.modalParentRef.current) {
            return
        }

        if (this.state.opened) {
            this.unregisterOutsideClick && this.unregisterOutsideClick()
            Morpheus.closeView(this.props.name)
        }
    }

    private handleBack() {
        Morpheus.getHistory().goBack()
    }

    private handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
        event.key === "Escape" && this.handleClose()
    }

    render() {
        const className = `modal__container ${this.state.opened ? "open" : ""}`
        const hasModalBack = location.search.includes("hasModalBack")

        return (
            <Box className={className} ref={this.modalParentRef} onKeyDown={this.handleKeyPress} tabIndex={0}>
                <Box className="modal" ref={this.modalRef}>
                    <Box className="modal__body">{this.state.opened && this.props.children}</Box>
                    <StyledButton
                        color="primary"
                        startIcon={<Icon name={hasModalBack ? "arrow-left" : "close"} />}
                        onClick={hasModalBack ? this.handleBack : () => this.handleClose()}
                        opened={this.state.opened}
                    />
                </Box>
            </Box>
        )
    }
}

export default Modal
