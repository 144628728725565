import { useQuery } from "react-query"
import { getLoginShareKey } from "../getLoginShareKey"

export function useLoginShareKey(enabled: boolean) {
    const { data, isLoading, isError } = useQuery(["WORKTASK_GET_LOGIN_SHARE_KEY", enabled], () => getLoginShareKey(), {
        retry: 3,
        cacheTime: 0,
        enabled
    })

    return { isError, isLoading, shareKey: data }
}
