import { EmbeddedElement } from "@tm/models"
import { AppConfig } from "../data/loadConfiguration"

function addElement(prop: EmbeddedElement, targetName: "body" | "head") {
    const element = document.createElement(prop?.element ?? "script")

    if (prop.src && element instanceof HTMLScriptElement) {
        element.src = prop.src
        element.async = prop.async ?? false
        element.defer = prop.defer ?? false
    }

    if (prop.attribute?.name && prop.attribute?.value) {
        element.setAttribute(prop.attribute.name, prop.attribute.value)
    }

    if (prop.innerHTML) {
        element.innerHTML = prop.innerHTML
    }

    const target = document.getElementsByTagName(targetName)[0]
    target.appendChild(element)
}

function filterByEnv(element: EmbeddedElement | undefined, config: AppConfig): boolean {
    if (!element) {
        return false
    }

    if (!element.env) {
        return true
    }

    if (!Array.isArray(element.env)) {
        return element.env === config.params.env
    }

    return element.env.includes(config.params.env)
}

export function appendExternalScripts(config: AppConfig) {
    const {
        params: { externalScripts },
    } = config

    if (!externalScripts || typeof externalScripts !== "object") {
        return
    }

    Object.values(externalScripts).forEach((script) => {
        if (!script) {
            return
        }

        Object.entries(script).forEach(([key, elements]) => {
            const lowerCaseKey = key.toLocaleLowerCase()

            if (lowerCaseKey !== "body" && lowerCaseKey !== "head") {
                return
            }

            elements
                .filter((element) => filterByEnv(element, config))
                .forEach((element) => {
                    addElement(element, lowerCaseKey)
                })
        })
    })
}
