import { Alert, Stack, styled } from "@tm/components"
import { uniqueId } from "@tm/utils"
import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react"
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { snackbarState } from "."
import { SnackbarMessageComponent } from "./SnackbarMessage"

const SnackbarStack = styled(Stack)(({ theme }) => ({
    position: "fixed",
    zIndex: 7,
    width: 300,
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    ".MuiAlert-message": {
        flex: 1,
    },
}))

export function SnackbarProvider(props: PropsWithChildren<unknown>) {
    const messages = useRecoilValue(snackbarState)
    return (
        <>
            {props.children}
            {!!messages.length && (
                <SnackbarStack>
                    {messages.map((message) => (
                        <SnackbarMessageComponent key={message.id} {...message} />
                    ))}
                </SnackbarStack>
            )}
        </>
    )
}
