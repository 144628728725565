import { setStoredLogin } from "@tm/utils"

export function storeToken(token: string, locale: string) {
    setStoredLogin({
        username: "",
        token,
        schema: "Bearer",
        expireDate: new Date(Date.now() + 43199 * 1000),
        locale,
    })
}
