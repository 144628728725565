import { useMutation } from "react-query"
import { useCallback } from "react"
import { ERIKContentService } from "../.."
import { GetEmailTemplateRequest } from "../models"

export function useGetEmailTemplate() {
    const { isLoading, mutateAsync } = useMutation(ERIKContentService.getEmailTemplate)

    const getEmailTemplate = useCallback(
        (content: unknown, templateName: string) => {
            const request: GetEmailTemplateRequest = {
                serializedContent: JSON.stringify(content),
                templateName,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { isLoading, getEmailTemplate }
}
