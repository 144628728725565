import { ReactNode } from "react"
import { AssetLoadProgress } from "@tm/morpheus"
import { createRoot, Root } from "react-dom/client"
import { elementId } from "@tm/utils"

let splashRoot: Root

function getSplashRoot() {
    if (splashRoot) {
        return splashRoot
    }
    const splashContainer = document.querySelector("#splash-screen")
    if (!splashContainer) {
        return
    }
    splashRoot = createRoot(splashContainer, { identifierPrefix: elementId("splash") })
    return splashRoot
}

function CatalogNotFound(props: { catalog: string }) {
    return (
        <div className="config-error">
            Catalog <strong>{props.catalog}</strong> not found.
        </div>
    )
}

export function handleConfigurationNotFound(catalog: string) {
    getSplashRoot()?.render(<CatalogNotFound catalog={catalog} />)
}

function Loader(props: { children: ReactNode }) {
    return <div className="asset-loader">{props.children}</div>
}

export function ConfigurationLoader() {
    return <Loader>Loading configuration...</Loader>
}

export function LoginLoader() {
    return <Loader>Loading login...</Loader>
}

export function AssetLoader(props: { loadedCount: number; totalCount: number }) {
    return (
        <Loader>
            Loading modules: {props.loadedCount} ({props.totalCount})
        </Loader>
    )
}

export function handleConfigurationLoadProgress() {
    getSplashRoot()?.render(<ConfigurationLoader />)
}

export function handleAssetLoadProgress(progress: Array<AssetLoadProgress>, totalCount?: number) {
    getSplashRoot()?.render(<AssetLoader loadedCount={progress.filter((x) => x.ready).length} totalCount={totalCount ?? 0} />)
}

export function resetLoadProgress() {
    const container = document.querySelector("#splash-screen")

    if (container?.firstChild) {
        container.removeChild(container.firstChild)
    }
}
