import { useCallback, useEffect, useMemo, useRef } from "react"
import { SnackbarMessage, snackbarState } from "."
import { Alert } from "@tm/components"
import { useSetRecoilState } from "recoil"

export function SnackbarMessageComponent(props: SnackbarMessage) {
    const { id, type, icon, isCloseable, content, autoClose } = props
    const setMessages = useSetRecoilState(snackbarState)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        handleMouseLeave()
    }, [autoClose])

    const handleMouseEnter = useCallback(() => {
        clearTimeout(timerRef.current)
    }, [])

    const handleMouseLeave = useCallback(() => {
        clearTimeout(timerRef.current)
        if (autoClose) {
            timerRef.current = setTimeout(() => setMessages((state) => state.filter((x) => x.id !== id)), 5e3)
        }
    }, [])

    const handleClose = useMemo(() => {
        if (isCloseable) {
            return () => {
                clearTimeout(timerRef.current)
                setMessages((state) => state.filter((x) => x.id !== id))
            }
        }
    }, [])

    return (
        <Alert variant="filled" severity={type} icon={icon} onClose={handleClose} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {content}
        </Alert>
    )
}
