export type GetTokensRequest = {
    tokenIdentifiers: TokenIdentifier[]
    refreshUserContext?: boolean
}

export type GetTokensResponse = {
    tokens: ExternalAuthenticationToken[]
    errors: string[]
}

export type TokenIdentifier = {
    repository: ETokenRepositories
    id?: string
    options?: Record<string, string>
}

export type ExternalAuthenticationToken = {
    repository: ETokenRepositories
    id?: string
    variant?: ETokenVariants
    token: string
    expiryDate?: Date
    schema?: string
}

export enum ETokenRepositories {
    Unknown = 0,
    OAuthDatabase = 1,
    PvPlus = 2,
    SeatbeltCrm = 3,
    Wm = 4,
    ExternalModule = 5,
    Neimcke = 6,
    Rhiag = 7,
    DevspireCms = 8,
    StgRetoure = 9,
    FastUp = 10,
}

export enum ETokenVariants {
    Unknown = 0,
    AccessToken = 1,
    RefreshToken = 2,
}
