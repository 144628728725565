import { useState, useRef, useEffect } from "react"
import { channel } from "@tm/models"
import { QuestionPrompt } from "@tm/controls"

export interface ConfirmationProps {
    message: string
    time?: Date
    confirmationButtonText?: string
    cancelButtonText?: string
    layout?: "stretch"
    onClose?(): void
    onConfirm?(): void
    onCancel?(): void
}
let ConfirmationQueue: ConfirmationProps[] = []
/** *
 * @description Shows ConfirmationDialog, FIFO
 */
export function ConfirmationMessageHandler() {
    const [questionRequest, setQuestionRequest] = useState<ConfirmationProps>()
    const requestRef = useRef(questionRequest)
    requestRef.current = questionRequest

    const subscriptionHandler = (confirmationRequest: ConfirmationProps) => {
        if (requestRef.current) {
            ConfirmationQueue.push(confirmationRequest)
        } else {
            setQuestionRequest(confirmationRequest)
        }
    }

    useEffect(() => {
        channel("APP").subscribe("CONFIRMATION/SHOW", subscriptionHandler)
    }, [])

    if (!questionRequest) {
        if (ConfirmationQueue.length > 0) {
            setQuestionRequest(ConfirmationQueue.first())
            ConfirmationQueue = ConfirmationQueue.slice(1)
        }
        return null
    }

    const { onClose, message, confirmationButtonText, ...rest } = questionRequest

    const removeQuestionOnClose = () => {
        onClose && onClose()
        setQuestionRequest(undefined)
    }

    return (
        <QuestionPrompt
            {...rest}
            ref={(ref: any) => {
                ref?.show()
            }}
            text={message}
            confirmationButtonText={confirmationButtonText || "yez"}
            onClose={removeQuestionOnClose}
        />
    )
}
