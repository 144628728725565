import { ExternalModule, channel, UserContext } from "@tm/models"
import { encodeUniqueId, createQueryString, uniqueId } from "@tm/utils"
import { History } from "history"

export function redirectToExternalSystem(history: History, response: { context: UserContext }) {
    const externalModuls = response.context.externalModules?.filter((ext) =>
        ext.parameter?.find((param) => param.key == "Homepage" && param.value == "1")
    )
    const externalModul = externalModuls?.first()

    if (externalModul) {
        const homepageShown = sessionStorage.getItem("HOMEPAGE_SHOWN") == "1"
        if (!homepageShown) {
            openExternalCatalog(externalModul, history)
            sessionStorage.setItem("HOMEPAGE_SHOWN", "1")
        }
    }
}

function openExternalCatalog(module: ExternalModule, history: History) {
    const plainWorkTaskId = openExternalModulInNewWorktask(module) ? uniqueId() : ""
    const externalUrl = createExternalUrl(module, plainWorkTaskId)

    history.push(externalUrl)

    channel("WORKTASK", plainWorkTaskId).publish("MODULE/OPENED", {
        isSelected: true,
        title: module.description,
        url: externalUrl,
        icon: getIconName(module),
    })
}

function createExternalUrl(module: ExternalModule, plainWorktaskId?: string) {
    const url = encodeURIComponent(module.url)
    const queryString = createQueryString({ url })

    if (plainWorktaskId) {
        return `/${encodeUniqueId(plainWorktaskId)}/ext/${queryString}`
    }
    return `/external01${queryString}`
}

function openExternalModulInNewWorktask(item: ExternalModule) {
    const openWorkTaskParam = item.parameter?.find((item) => item.key.toLowerCase() == "openinworktask" || item.key.toLowerCase() == "455")
    return openWorkTaskParam?.value == "1" || openWorkTaskParam?.value == "true"
}

function getIconName(item: ExternalModule) {
    return item.parameter?.find((item) => item.key.toLowerCase() == "icon")?.value
}
