import { IModule, IModules } from "@portal/types"
import { getUserContext } from "@tm/context-distribution"
import { AddOnDto, KeyValue, SystemType, UserContext, UserModuleType } from "@tm/models"
import cloneDeep from "clone-deep"
import merge from "lodash/merge"
import xhrMock, { proxy } from "xhr-mock"
import { getSavedCatalog } from "../bootstrap/document/currentCatalogStorage"
import { MODULES } from "./mocksModules"
import { mockParams } from "./params"
import { REWRITES } from "./rewrites"

export function initMock() {
    if (!process.env.ENABLE_MOCK) {
        return
    }

    xhrMock.setup()

    if (!process.env.DISABLE_UI_CONFIG_MOCK) {
        mockConfigurationService()
    }

    xhrMock.use(proxy)
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

function convertKeysToPascalCase(obj: any): any {
    if (typeof obj !== "object" || obj == null) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map(convertKeysToPascalCase)
    }

    const result: Record<string, any> = {}
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const pascalCaseKey = key.charAt(0).toUpperCase() + key.slice(1)
            result[pascalCaseKey] = convertKeysToPascalCase(obj[key])
        }
    }

    return result
}

const BASE_IDENTIFIER = "__default__"

function mockConfigurationService() {
    if (!xhrMock) {
        return
    }

    const loadLoginOrPortalConfiguration = /\/data\/TM\.Next\.Authority\/.*\/(GetLoginConfig\?identifier\=\w+|GetAppConfig)/i

    return xhrMock.get(loadLoginOrPortalConfiguration, async (request, response) => {
        // Wait for NEXT DEV-TOOL to load and inject script
        await sleep(500)

        const NEXT_DEVTOOL_ENABLED = window.__NEXT_DEVTOOLS_EXTENSION__?.enabled ?? false

        let identifier = "default"
        let type = "login"

        const urlMock = request.url()
        const params = urlMock.query ?? {}

        if (params.identifier) {
            identifier = params.identifier
            type = "login"
        } else {
            identifier = getSavedCatalog()
            type = "app"

            switch (identifier.toUpperCase()) {
                case "ELEKAT_LIVE":
                    identifier = "elekat"
                    break

                case "ESA-TEST":
                    identifier = "esa"
                    break

                case "NEIMCKE-TEST":
                    identifier = "neimcke"
                    break

                case "PV_INT":
                case "PV-TELESALES":
                    identifier = "pv"
                    break

                case "PV-EOS-LAB":
                case "PV-TELESALES-EOS-LAB":
                    identifier = "pv-eos"
                    break

                case "STG-EOS-LAB":
                    identifier = "stg-eos"
                    break

                case "STG-TELESALES-EOS-LAB":
                    identifier = "stg-telesales-eos"
                    break

                case "STGAUT-TEST":
                    identifier = "stg-aut"
                    break

                case "TECCAT-RD":
                    identifier = "teccat_standalone"
                    break
                case "WM-TELESALES-TEST":
                    identifier = "wm-telesales"
                    break
                default:
            }
        }

        let modules = cloneDeep(MODULES)
        let userContext

        if (type === "app") {
            userContext = await getUserContext("/data/TM.Next.Authority").catch((error) => {
                console.log("error getUserContext", error)
                return undefined
            })
            modules = handleModuleActivationByUserContext(userContext, identifier)
        }

        if (NEXT_DEVTOOL_ENABLED) {
            window.__NEXT_DEVTOOLS_EXTENSION__!.getMockData = () => ({ modules, initialModules: MODULES, rewrites: REWRITES, mockParams })
        }

        return loadConfiguration(modules, identifier, type, userContext).then((config) => {
            const selectedData: Record<string, string> = JSON.parse(localStorage.getItem("params") ?? "[]")

            Object.entries(mockParams).forEach(([key, [defaultValue]]) => {
                const val = selectedData[key]?.[0] ?? defaultValue
                const regex = new RegExp(`\{_${key}_\}|<%${key}%>`, "g")
                config = config.replace(regex, val)
            })

            if (hasPlaceHolder(config)) {
                console.log("ui-config parameters left unreplaced!", config.match(placeholderPatternRegEx))
            }

            config = config.replace(/\{_(.*?)_\}/gm, "")
            // remove remaining  addons
            config = config.replace(/\{\[(.*?)\]\}/gm, "")

            if (NEXT_DEVTOOL_ENABLED) {
                const localStorageRewrites = JSON.parse(localStorage.getItem("rewrites") ?? "[]")
                const localRewrites = REWRITES?.filter((x) => x.some((x) => x === true)).map((x) => x[0])
                const mergedRewrites = [...localStorageRewrites, ...localRewrites].distinct()

                mergedRewrites.forEach((key: string) => {
                    const item = REWRITES.find((x) => x[0] == key)

                    if (item) {
                        config = config.replace(item[0], item[1])
                    }
                })
            } else {
                REWRITES.filter((x) => x[2]) // check if rewrite is activated: third item is "true"
                    .forEach((r) => (config = config.replace(r[0], r[1])))
            }

            return response.status(200).body(config)
        })
    })
}

type Configs<T> = {
    appBase: T
    app: T
    controlsBase: T
    controls: T
    modules: Array<T>
    systemModules: Array<T>
    moduleParameters: Array<T>
    parameters: Array<T>
    otherModules: Array<T>
    externalSystems: Array<T>
    lateModules: Array<T>
    trader: Array<T>
}

const CONFIG_BASE_URL = "/ui-configs"

const filterEnabledModules = (devToolEnabled: boolean, moduleRewrites: IModule[]) => (module: IModule) => {
    const name = module[0].toLowerCase()

    if (moduleRewrites?.length && devToolEnabled) {
        const rewrite = moduleRewrites.find((x) => x[0].toLowerCase() === name)
        if (rewrite) {
            return rewrite[1]
        }
    }

    return module[1]
}

const placeholderPatternRegEx = /\{_(.*?)_\}/g
const hasPlaceHolder = (template: string) => placeholderPatternRegEx.test(template)

function augmentParameters(
    moduleName: string,
    template: string,
    parameters: KeyValue[],
    overriddenParams: Record<string, string>,
    matchPassedParametersOnly = false,
    defaultValue: string | null = null
): string {
    template = template.replace(placeholderPatternRegEx, (match: string, placeholder: string) =>
        getParameterValue(moduleName, placeholder, parameters, overriddenParams, matchPassedParametersOnly, defaultValue)
    )
    return template
}

const placeholderPattern = "{{_{0}_}}"
function getParameterValue(
    moduleName: string,
    placeholder: string,
    parameters: KeyValue[],
    overriddenParams: Record<string, string>,
    matchPassedParametersOnly: boolean,
    defaultValue: string | null = null
): string {
    const value: string = defaultValue !== null ? defaultValue : placeholder

    const param = parameters?.find((p) => p.key === placeholder)
    if (param) {
        return param.value
    }

    const mockParam = Object.entries(mockParams).find((x) => x[0] === placeholder)
    if (mockParam) {
        const val = overriddenParams[placeholder]?.[0] ?? mockParam[1][0]
        return val
    }

    if (matchPassedParametersOnly) {
        return placeholderPattern.replace("{0}", placeholder)
    }

    console.log("ui-config parameters left unreplaced!", moduleName, placeholder)
    return value
}

const placeholderAddOnPatternRegEx = /"\{\[(.*?)\]}"/g

function getAddOnsValue(placeholder: string, addOns: AddOnDto[]): string {
    let value: string = placeholder

    const addOn = addOns?.find((a) => a.id === 93)

    if (addOn) {
        try {
            value = JSON.stringify(convertKeysToPascalCase(addOn.keys))
        } catch (ex) {
            console.log("addOn stringify failed", addOns)
        }
    }

    return value
}

function augmentAddOns(template: string, addOns: AddOnDto[]): string {
    template = template.replace(placeholderAddOnPatternRegEx, (match: string) => getAddOnsValue(match, addOns))
    return template
}

const augmentTemplate =
    (type: keyof IModules | "", module: string, overriddenParams: Record<string, string>, userContext?: UserContext) => (body: any) => {
        let content: string = body

        if (!hasPlaceHolder(content) || !userContext) {
            return JSON.parse(content)
        }

        let moduleName = module.toLowerCase()

        const match = moduleName.match(new RegExp(`^${userContext.system.systemType}/(.*)$`, "i"))
        if (match) {
            moduleName = match[1]
        }

        switch (type) {
            case "mdmModules":
            case "systemModules":
            case "mdmModuleParameters": {
                const module = userContext?.modules?.find((mod) => mod.description?.toLowerCase().replace(/ /g, "") === moduleName)
                content = augmentParameters(moduleName, content, module?.parameters ?? [], overriddenParams, false, "")
                content = augmentAddOns(content, module?.addOns || [])

                break
            }
            case "mdmParameters": {
                const parameters = Object.keys(userContext?.parameter ?? {})?.map((key) => <KeyValue>{ key, value: userContext.parameter[key] }) || []
                content = augmentParameters(moduleName, content, parameters, overriddenParams, false, "")
                break
            }
            default: {
                content = augmentParameters(moduleName, content, [], overriddenParams, false, "")
                break
            }
        }

        if (hasPlaceHolder(content)) {
            console.log("ui-config parameters left unreplaced!", type, module, moduleName, content.match(placeholderPatternRegEx))
        }

        return JSON.parse(content)
    }

function loadOptionalUrl(isLocalDebug: boolean, url: string | undefined, warning?: string, raw = false): Promise<Record<string, any>> {
    return loadUrl(url, raw).catch(() => {
        if (isLocalDebug) {
            console.warn(
                warning ??
                    `Configuration \"${url}\" could not be found. Maybe you haven't pulled the latest changes in the ui-configs project or the config is deprecated?`
            )
        }

        return raw ? "" : {}
    })
}

async function loadConfiguration(modules: IModules, identifier: string, type: string, userContext?: UserContext) {
    const NEXT_DEVTOOL_ENABLED = !!window.__NEXT_DEVTOOLS_EXTENSION__?.enabled

    let moduleRewrites: IModule[] = []
    try {
        moduleRewrites = JSON.parse(sessionStorage.getItem("moduleRewrites") ?? "[]")
    } catch (ex) {}

    const mdmModules = modules.mdmModules.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))
    const systemModules = Object.fromEntries(
        Object.entries(modules.systemModules).map(([key, modules]) => [
            key,
            modules.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites)),
        ])
    )
    const mdmModuleParameters = modules.mdmModuleParameters.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))
    const mdmParameters = modules.mdmParameters.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))
    const otherModules = modules.otherModules.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))
    const externals = modules.externalSystems[identifier]?.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites)) || []
    const lateModules = modules.lateModules.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))
    const traderConfigs = modules.traderConfigs.filter(filterEnabledModules(NEXT_DEVTOOL_ENABLED, moduleRewrites))

    const configUrls: Configs<string[]> = {
        appBase: ["", `${CONFIG_BASE_URL}/${type}/${BASE_IDENTIFIER}.json`], // base app config: "__default__.json"
        app: ["", `${CONFIG_BASE_URL}/${type}/${identifier}.json`], // catalog app config
        controlsBase: ["", `${CONFIG_BASE_URL}/controls/base.json`], // base controls config: contains some default values for control props
        controls: ["", `${CONFIG_BASE_URL}/controls/${identifier}.json`], // catalog controls config
        modules: [],
        systemModules: [],
        moduleParameters: [],
        parameters: [],
        otherModules: [],
        externalSystems: [],
        lateModules: [],
        trader: [],
    }

    if (type !== "login") {
        configUrls.modules = mdmModules.map((mod) => [mod[0], `${CONFIG_BASE_URL}/modules/${mod?.[0].toLowerCase()}.json`])
        configUrls.systemModules = Object.entries(systemModules).flatMap(([systemType, modules]) =>
            modules.map((mod) => [mod[0], `${CONFIG_BASE_URL}/modules/system_type_${systemType}/${mod[0]?.toLowerCase()}.json`])
        )
        configUrls.moduleParameters = mdmModuleParameters.map((mod) => [
            mod[0],
            `${CONFIG_BASE_URL}/modules/parameters/${mod?.[0].toLowerCase()}.json`,
        ])
        configUrls.parameters = mdmParameters.map((mod) => [mod[0], `${CONFIG_BASE_URL}/parameters/${mod?.[0].toLowerCase()}.json`])
        configUrls.otherModules = otherModules.map((mod) => [mod[0], `${CONFIG_BASE_URL}/externalcatalogs/${mod?.[0].toLowerCase()}.json`])
        configUrls.externalSystems = externals.map((externalCatalogId) => [
            externalCatalogId[0],
            `${CONFIG_BASE_URL}/externalcatalogs/${identifier}/${externalCatalogId[0]}.json`,
        ])
        configUrls.lateModules = lateModules.map((mod) => [mod[0], `${CONFIG_BASE_URL}/late-modules/${mod[0].toLowerCase()}.json`])
        configUrls.trader = traderConfigs.map((mod) => [mod[0], `${CONFIG_BASE_URL}/trader/${mod[0].toLowerCase()}.json`])
    }

    const isLocalDebug = location.origin.includes("localhost")

    let overriddenParams: Record<string, string> = {}
    try {
        overriddenParams = JSON.parse(localStorage.getItem("params") ?? "[]")
    } catch (ex) {}

    const configs: Configs<Record<string, any>> = {
        appBase: await loadUrl(configUrls.appBase[1]),
        app: await loadUrl(configUrls.app[1]),
        controlsBase: await loadUrl(configUrls.controlsBase[1]),
        controls: await loadOptionalUrl(
            isLocalDebug,
            configUrls.controls[1],
            `\"GET ${configUrls.controls} 404 (Not Found)\" should only appear on local environments and can be ignored`
        ),
        modules: await Promise.all(
            configUrls.modules.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("mdmModules", mod, overriddenParams, userContext))
            )
        ),
        systemModules: await Promise.all(
            configUrls.systemModules.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("systemModules", mod, overriddenParams, userContext))
            )
        ),
        moduleParameters: await Promise.all(
            configUrls.moduleParameters.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("systemModules", mod, overriddenParams, userContext))
            )
        ),
        parameters: await Promise.all(
            configUrls.parameters.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("mdmParameters", mod, overriddenParams, userContext))
            )
        ),
        otherModules: await Promise.all(
            configUrls.otherModules.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("otherModules", mod, overriddenParams, userContext))
            )
        ),
        externalSystems: await Promise.all(
            configUrls.externalSystems.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("externalSystems", mod, overriddenParams, userContext))
            )
        ),
        lateModules: await Promise.all(
            configUrls.lateModules.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("lateModules", mod, overriddenParams, userContext))
            )
        ),
        trader: await Promise.all(
            configUrls.trader.map(([mod, url]) =>
                loadOptionalUrl(isLocalDebug, url, undefined, true).then(augmentTemplate("traderConfigs", mod, overriddenParams, userContext))
            )
        ),
    }

    // IMPORTANT: the order of the configs here must match the merge order of the Authority
    const mergedConfig = merge(
        !configs.app.preventDefault ? configs.appBase : {},
        configs.controlsBase,
        ...configs.modules,
        ...configs.systemModules,
        ...configs.moduleParameters,
        ...configs.parameters,
        ...configs.otherModules,
        ...configs.externalSystems,
        configs.app,
        ...configs.trader,
        ...configs.lateModules,
        configs.controls
    )

    return JSON.stringify(mergedConfig)
}

function loadUrl(url: string | undefined, raw = false): Promise<Record<string, any>> {
    if (!url) {
        return Promise.resolve(raw ? "" : {})
    }

    return new Promise<{}>((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("GET", url)

        xhr.onloadend = () => {
            if (xhr.readyState == 4) {
                if (xhr.status != 200) {
                    reject()
                }

                if (xhr.responseType == "json") {
                    resolve(xhr.response)
                    return
                }

                try {
                    resolve(raw ? xhr.responseText : JSON.parse(xhr.responseText) || {})
                } catch (e) {
                    reject(e)
                }

                return
            }

            reject()
        }

        xhr.send()
    })
}

function handleModuleActivationByUserContext(userContext: UserContext | undefined, identifier: string) {
    const modules = cloneDeep(MODULES)

    if (!userContext) {
        return modules
    }

    const { systemType } = userContext.system

    userContext.modules?.forEach((apiMdmModule) => {
        if (!apiMdmModule.description || !apiMdmModule.type || apiMdmModule.description === "Undefined") {
            return
        }

        const moduleName = apiMdmModule.description.toLowerCase()?.replace(/ /g, "")

        if (systemType == SystemType.Redesign) {
            const systemModule = modules.systemModules[systemType].find((x) => x[0].toLowerCase() == moduleName)

            if (systemModule) {
                systemModule[1] = true
            }
        } else {
            const mdmModule = modules.mdmModules?.find((x) => x[0].toLowerCase() == moduleName)

            if (mdmModule) {
                mdmModule[1] = true
            }
        }

        if (apiMdmModule.type === UserModuleType.HaynesPro && apiMdmModule.isAWDataEnabled) {
            const mdmModuleParameter = modules.mdmModuleParameters?.find((x) => x[0].toLowerCase() == "haynespro_awdata")

            if (mdmModuleParameter) {
                mdmModuleParameter[1] = true
            }
        }

        apiMdmModule.uiConfigKeys?.forEach((key) => {
            const mdmModuleParameter = modules.mdmModuleParameters?.find((x) => x[0].toLowerCase() == `${moduleName}_${key.toLowerCase()}`)

            if (mdmModuleParameter) {
                mdmModuleParameter[1] = true
            }
        })

        modules.mdmModuleParameters
            .filter((x) => x[0].startsWith(`${apiMdmModule.description.toLowerCase()}_`))
            .forEach((mdmModuleParameter) => {
                const [modParam, value] = mdmModuleParameter[0].split("__")
                const [_, param] = modParam.split("_")
                Object.entries(apiMdmModule).forEach(([moduleKey, moduleVal]) => {
                    if (moduleKey == param && moduleVal == value) {
                        mdmModuleParameter[1] = true
                    }
                })
            })
    })

    userContext.activatableModules?.forEach((activatableModule) => {
        if (!activatableModule.description || !activatableModule.type || activatableModule.description === "Undefined") {
            return
        }

        const moduleName = activatableModule.description.toLowerCase()?.replace(/ /g, "")

        activatableModule.uiConfigKeys?.forEach((key) => {
            const mdmModuleParameter = modules.mdmModuleParameters?.find((x) => x[0].toLowerCase() == `${moduleName}_${key}`)

            if (mdmModuleParameter) {
                mdmModuleParameter[1] = true
            }
        })
    })

    userContext.externalModules?.forEach((apiExternalModule) => {
        const externalSystemId = apiExternalModule.id.toString()

        const externalSystem = modules.externalSystems?.[identifier]?.find((x) => x[0] == externalSystemId)

        if (externalSystem) {
            externalSystem[1] = true
        }

        // {type}-{vehicleLookupType}, (3-9994 = DAT VIN)
        const lookupTypeParam = apiExternalModule.parameter?.find((x) => x.key == "VehicleLookupType" || x.key == "IDSearch")?.value

        if (lookupTypeParam) {
            const name = `${apiExternalModule.type}-${lookupTypeParam}`

            const otherModule = modules.otherModules?.find((x) => x[0] == name)
            if (otherModule) {
                otherModule[1] = true
            }
        }
    })

    modules.otherModules?.forEach((otherModule) => {
        const [modulePath] = otherModule

        if (!modulePath.startsWith(identifier)) {
            return
        }

        const externalSystemId = modulePath.split("/")[1]
        if (externalSystemId && userContext.externalModules?.find((x) => x.id.toString() === externalSystemId)) {
            otherModule[1] = true
        }
    })

    Object.keys(userContext.parameter).forEach((paramKey) => {
        if (!userContext.parameter[paramKey]) {
            return
        }

        const name = paramKey.toLowerCase()

        const mdmModule = modules?.mdmParameters?.find((x) => x[0].toLowerCase() == name)

        if (mdmModule) {
            mdmModule[1] = true
        }
    })

    const traderId = userContext!.principal!.traderId.toString()

    const traderConfig = modules?.traderConfigs?.find((x) => x[0].toLowerCase() == traderId)
    if (traderConfig) {
        traderConfig[1] = true
    }

    if (userContext.hasTelesales) {
        const lateModule = modules?.lateModules?.find((x) => x[0].toLowerCase() == "telesales")

        if (lateModule) {
            lateModule[1] = true
        }
    }

    if (userContext.account?.username === "devspire") {
        const lateModule = modules?.lateModules?.find((x) => x[0].toLowerCase() == "devspire")

        if (lateModule) {
            lateModule[1] = true
        }
    }

    return modules
}
