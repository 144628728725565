import { FileMeta } from "../../erikContentService"

export enum FileSortingField {
    DisplayName,
    FileSize,
    Prefix,
    UserPrefix,
    Kind,
    UpdatedAt,
    CreatedAt,
}

export type FileSortOrder = {
    field: FileSortingField
    descending: boolean
}

export type GetFilesRequest = {
    take: number
    vehicleId: string
    skip?: number
    search?: string
    sortOrder?: Array<FileSortOrder>
}

export type GetFilesResponse = {
    files: Array<FileMeta>
    total: number
}
