import axios from "axios"
import { AuthTokenPayload, LogEntry, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { decodeJwtToken, getStoredLogin } from "@tm/utils"
import { getSavedCatalog } from "../bootstrap/document/currentCatalogStorage"

export function initialize() {
    Container.register<LogEntry>({
        name: RegisteredModels.Logging,
        containerActions: {
            write,
        },
    })
}

export function write(log: LogEntry) {
    try {
        const now = new Date()

        const data: any = {
            timestamp: now.toISOString(),
            clientTimestamp: now.toISOString(),
            type: log.type,
            catalog: getSavedCatalog(),
            language: sessionStorage.getItem("language"),
            url: location.toString(),
            userAgent: navigator.userAgent,
            screen: {
                width: screen.width,
                height: screen.height,
            },
            window: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            shellMetaData: window.tmJSShellMeta,
        }

        const login = getStoredLogin()
        try {
            if (login) {
                data.username = login.username
                data.mdm = {
                    customerName: login.customerNo,
                }

                if (login.token && typeof login.token == "string") {
                    const tokenPayload = decodeJwtToken<AuthTokenPayload>(login.token)

                    data.username = tokenPayload.cust
                    data.mdm = {
                        ...data.mdm,
                        trader: { id: tokenPayload.tid },
                        catalog: { id: tokenPayload.cat },
                        ikndNr: tokenPayload.ikdnr.split("/")[0],
                        lfdNr: tokenPayload.ikdnr.split("/")[1],
                    }
                }
            }
        } catch (e) {
            data.token = login?.token
        }

        switch (log.type) {
            case "Component error":
                // body.exception = createStringFromUnknown(log.error)
                // break
                return
            case "Worktask error":
                data.message = log.message
                data.exception = createStringFromUnknown(log.error)
                break
            case "AJAX error":
                if (log.error.request.url && log.error.request.url.indexOf("/log/") === 0) {
                    return
                }

                try {
                    // Remove passwords for logging
                    if (log.error.request) {
                        log.error.request.body = removeSensitiveDataFromJSON(log.error.request.body)
                        log.error.request.url = removeSensitiveDataFromURL(log.error.request.url)
                    }
                } catch (e) {}

                data.exception = createStringFromUnknown(log.error)
                data.status = log.error.status
                data.message = log.error.message

                try {
                    let { response } = log.error

                    if (response) {
                        if (typeof response != "string") {
                            response = JSON.stringify(response)
                        }

                        data.ajaxResponse = removeSensitiveDataFromJSON(response)
                    }
                } catch (e) {}

                break
            case "Login failed": {
                data.username = log.username
                break
            }
            case "API Call": {
                data.origin = log.origin
                data.sourceId = log.sourceId
                data.command = log.command
                data.data = log.data
                break
            }
            case "Alternative article search started": {
                data.searchType = log.searchType
                data.article = log.article
                break
            }
            case "Alternative article search - article details opened": {
                data.request = log.request
                break
            }
            case "ERE process": {
                data.message = log.message
                break
            }
        }

        axios.request({
            method: "POST",
            url: `/data/TM.Next.Logging/log/ui`,
            data,
            headers: {
                "Content-Type": "application/json",
            },
        })
    } finally {
    }
}

function createStringFromUnknown(obj: any) {
    let _tmp: any

    if (typeof obj != "object") {
        _tmp = {
            message: !obj ? "Unknown" : obj.toString(),
        }
        obj = _tmp
    } else {
        _tmp = {}
        Object.getOwnPropertyNames(obj).forEach((key) => {
            _tmp[key] = obj[key]
        })
    }

    return JSON.stringify(_tmp)
}

function removeSensitiveDataFromJSON(json: string): string {
    return json.replace(/"(password|passwort|pass|pw)"(.*?):(.*?)".*?"/g, '"$1"$2:$3"<REMOVED>"')
}

function removeSensitiveDataFromURL(url: string): string {
    return url.replace(/(password|passwort|pass|pw)=.*?(&|$)/g, "$1=<REMOVED>$2")
}
