export const WebserviceUrl = {
    AddressPickerCountries: "/data/TM.Next.AddressPicker/Countries",
    AddressPickerRegions: "/data/TM.Next.AddressPicker/Regions",
    AddressPickerStreets: "/data/TM.Next.AddressPicker/Streets",
    Authority: "/data/TM.Next.Authority/Authority",
    AuthorityModulStore: "/data/TM.Next.Authority/ModulStore",
    AuthorityThirdPartyLogin: "/data/TM.Next.Authority/ThirdPartyLogin",
    AuthorityUrl: "/data/TM.Next.Authority/Url",
    AuthorityExternalCredentials: "/data/TM.Next.Authority/external/credentials",
    AuthorityExternalAuthentication: "/data/TM.Next.Authority/externalauthentication",
    AuthoritySubUser: "/data/TM.Next.Authority/subuser",
    AuthorityUser: "/data/TM.Next.Authority/user",
    BasketThirdPartyBasketExternal: "/data/TM.Next.Basket.External/ThirdPartyBasket",
    Basket: "/data/TM.Next.Basket/Basket",
    BasketCostEstimation: "/data/TM.Next.Basket/CostEstimation",
    BasketOrder: "/data/TM.Next.Basket/Order",
    BasketWorks: "/data/TM.Next.Basket/Works",
    BasketFastCalculation: "/data/TM.Next.Basket/fastCalculation",
    BasketParts: "/data/TM.Next.Basket/parts",
    BasketRepairEstimation: "/data/TM.Next.Basket/repairEstimation",
    CatalogArticles: "/data/TM.Next.Catalog/Articles",
    CatalogArticlesCustom: "/data/TM.Next.Catalog/Articles/Custom",
    CatalogArticlesDigitalForms: "/data/TM.Next.Catalog/Articles/DigitalForms",
    CatalogArticlesPrintArticleDetails: "/data/TM.Next.Catalog/Articles/PrintArticleDetails",
    CatalogFastDTC: "/data/TM.Next.Catalog/FastDTC",
    CatalogMemoTool: "/data/TM.Next.Catalog/MemoTool",
    CatalogPredictive: "/data/TM.Next.Catalog/Predictive",
    CisBackorders: "/data/TM.Next.Cis/Backorders",
    CisCustomer: "/data/TM.Next.Cis/Customer",
    CisInvoices: "/data/TM.Next.Cis/Invoices",
    CisOptions: "/data/TM.Next.Cis/Options",
    CisOrderConfirmations: "/data/TM.Next.Cis/OrderConfirmations",
    CisReturnItems: "/data/TM.Next.Cis/ReturnItems",
    CisTours: "/data/TM.Next.Cis/Tours",
    CisVouchers: "/data/TM.Next.Cis/Vouchers",
    CompilationsArticleCompilationsWm: "/data/TM.Next.Compilations/ArticleCompilations",
    CompilationsArticleCompilationsEmbedded: "/data/TM.Next.Compilations/ArticleCompilations/embedded",
    DamageCalculationBusiness: "/data/TM.Next.DamageCalculation/Business",
    DamageCalculationCarInfo: "/data/TM.Next.DamageCalculation/CarInfo",
    DamageCalculationCustomer: "/data/TM.Next.DamageCalculation/Customer",
    DamageCalculationDatCalculation: "/data/TM.Next.DamageCalculation/DatCalculation",
    DamageCalculationEreCalculation: "/data/TM.Next.DamageCalculation/EreCalculation",
    DamageCalculationAsWegasCalculation: "/data/TM.Next.DamageCalculation/aswegascalculation",
    DamageCalculationGlassCalculation: "/data/TM.Next.DamageCalculation/glasscalculation",
    DataPickerV1: "/data/TM.Next.DataPicker/DataPicker",
    DataPickerV7: "/data/TM.Next.DataPicker/DataPickerV7",
    DataStoreFastServiceHistories: "/data/TM.Next.DataStore/Histories",
    DataStoreTMWConfigurator: "/data/TM.Next.DataStore/TMWConfigurator",
    DmsIqt: "/data/TM.Next.Dms/IQT",
    DmsCxml: "/data/TM.Next.Dms/cXML",
    DmsGsiElekat: "/data/TM.Next.Dms/gsi/Elekat",
    DmsGsiConnect: "/data/TM.Next.Dms/gsi/connect",
    DmsOci: "/data/TM.Next.Dms/oci/V4",
    DutchOrderPlates: "/data/TM.Next.DutchOrderPlates",
    ErpConnect: "/data/TM.Next.ErpConnect/erpconnect",
    ErpOrderOptions: "/data/TM.Next.ErpConnect/orderoptions",
    FastCalculator: "/data/TM.Next.FastCalculator/FastCalculator",
    FastService: "/data/TM.Next.FastCalculator/fastservice",
    GpiEds: "/data/TM.Next.Gpi/Eds",
    EurotaxGraphicSelection: "/data/TM.Next.Gpi/Eurotax/GraphicSelection",
    EurotaxImagePositions: "/data/TM.Next.Gpi/Eurotax/Images",
    EurotaxMastercodeSelection: "/data/TM.Next.Gpi/Eurotax/MastercodeSelection",
    EurotaxParts: "/data/TM.Next.Gpi/Eurotax/Parts",
    EurotaxVehicleSelection: "/data/TM.Next.Gpi/Eurotax/VehicleSelection",
    GsiCustomers: "/data/TM.Next.Gsi/Customers",
    GsiVehicles: "/data/TM.Next.Gsi/Vehicles",
    OeResearchOe: "/data/TM.Next.OeResearch/OE",
    OeResearchOens: "/data/TM.Next.OeResearch/OENS",
    RepairShops: "/data/TM.Next.RepairShops.Portal/RepairShops",
    RepairTimes: "/data/TM.Next.RepairTimes/RepairTimes",
    RepairTimesCustom: "/data/TM.Next.RepairTimes/RepairTimes/Custom",
    RepairTimesTechnicalData: "/data/TM.Next.RepairTimes/RepairTimes/TechnicalData",
    StateManagementKeyValues: "/data/TM.Next.StateManagement/KeyValues",
    StateManagementStates: "/data/TM.Next.StateManagement/States",
    TelematicsCarmunication: "/data/TM.Next.Telematics.Portal/carmunication",
    TelematicsDrivemotive: "/data/TM.Next.Telematics.Portal/drivemotive",
    TelematicsSindri: "/data/TM.Next.Telematics.Portal/sindri",
    Telematics: "/data/TM.Next.Telematics.Portal/telematics",
    Translations: "/data/TM.Next.Translations/Translations",
    UniArticles: "/data/TM.Next.UniArticles/Articles/UniArticles",
    VehicleRecordsEvents: "/data/TM.Next.VehicleRecords/Events",
    VehicleRecordsVehicle: "/data/TM.Next.VehicleRecords/Vehicle",
    VehiclesBikes: "/data/TM.Next.Vehicles/Vehicles/Bikes",
    VehiclesCars: "/data/TM.Next.Vehicles/Vehicles/Cars",
    VehiclesDataAvailabilities: "/data/TM.Next.Vehicles/Vehicles/DataAvailabilities",
    VehiclesOptions: "/data/TM.Next.Vehicles/Vehicles/Options",
    VehiclesPrint: "/data/TM.Next.Vehicles/Vehicles/Print",
    VehiclesTrucks: "/data/TM.Next.Vehicles/Vehicles/Trucks",
    VehiclesVrcLookup: "/data/TM.Next.Vehicles/Vehicles/VrcLookup",
    VehiclesVrmLookup: "/data/TM.Next.Vehicles/Vehicles/VrmLookup",
    VinPicker: "/data/TM.Next.VinPicker/VinPicker",
    VouchersOrders: "/data/TM.Next.Vouchers/Orders",
    VouchersReturns: "/data/TM.Next.Vouchers/Returns",
    Vouchers: "/data/TM.Next.Vouchers/Vouchers",
    VouchersVefiles: "/data/TM.Next.Vouchers/vefiles",
    WheelsDriveRight: "/data/TM.Next.Wheels/DriveRight",
    WheelsFilters: "/data/TM.Next.Wheels/Filters",
    WheelsRims: "/data/TM.Next.Wheels/Rims",
    WheelsTyres: "/data/TM.Next.Wheels/Tyres",
    WheelsVehicle: "/data/TM.Next.Wheels/Vehicle",
    WorktasksTelesales: "/data/TM.Next.WorkTasks/Telesales",
    Worktask: "/data/TM.Next.WorkTasks/worktask",
    WorktaskNote: "/data/TM.Next.WorkTasks/worktask/Note",
}
