import { WebserviceUrl } from "@tm/data/webserviceUrls"
import { ajaxAuth } from "@tm/utils"
import { StandardTyreSize } from "@tm/models"

export function getStandardTyreSizes(): Promise<StandardTyreSize[]> {
    const url = `${WebserviceUrl.WheelsTyres}/GetStandardTyreSizes`

    return ajaxAuth({ url })
        .then((response) => response.standardTyreSizes ?? [])
        .catch((error) => {
            if (error.message.includes("404") || error.response?.status === 404) {
                return []
            }

            throw error
        })
}
