import {
    GetArticleListByMatchCodeRequest,
    GetArticleListByMatchCodeResponse,
    GetArticlesRequest,
    getCurrentWorkTaskId,
    GetUniArticlesResponse,
} from "@tm/models"
import { TmaHelper, mapArticleListResponse, ajaxAuth } from "@tm/utils"
import { WebserviceUrl } from "../../webserviceUrls"

export async function getArticleListByMatchCode(request: GetArticleListByMatchCodeRequest, defaultQuantity?: number) {
    const url = `${WebserviceUrl.UniArticles}/GetArticleListByMatchCode`
    const body = request

    const response = await ajaxAuth<GetArticleListByMatchCodeResponse>({ url, body, method: "POST" })

    if (!response) {
        throw new Error("Empty Response")
    }

    sendTmaInfo(url, request, response)

    if (!response.uniArticles?.length) {
        throw new Error("No results")
    }

    return mapArticleListResponse(response, defaultQuantity)
}

function sendTmaInfo(url: string, request: GetArticleListByMatchCodeRequest, response: GetArticleListByMatchCodeResponse) {
    TmaHelper.UniParts.List.Results(request as GetArticlesRequest, response as GetUniArticlesResponse, getCurrentWorkTaskId() ?? "", url, undefined)
}
