import { SetModuleActivationRequest } from "@tm/models"
import { useCallback, useEffect } from "react"
import { useQueryClient } from "react-query"
import { atom, useRecoilState, useResetRecoilState } from "recoil"
import { setModuleActivation } from "../modulStore/setModuleActivation"
import { KEY_ACTIVATABLE_MODULES } from "./useGetActivatableModules"

type ActivateModuleActivateModuleState = {
    loading: boolean
    error?: boolean
}

const activateModuleState = atom<ActivateModuleActivateModuleState>({
    key: "demo_Activate",
    default: {
        loading: false,
        error: false,
    },
})

export function useActivateModule(reload?: boolean) {
    const queryClient = useQueryClient()
    const [activateModuleValue, setActivateModule] = useRecoilState(activateModuleState)
    const activateModuleReset = useResetRecoilState(activateModuleState)

    useEffect(() => {
        activateModuleReset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activateModule = useCallback(
        (request: SetModuleActivationRequest) => {
            setActivateModule({ loading: true, error: false })

            return setModuleActivation(request)
                .then((res) => {
                    activateModuleReset()

                    if (reload) {
                        queryClient.resetQueries(KEY_ACTIVATABLE_MODULES)
                    }

                    return res
                })
                .catch((ex) => {
                    setActivateModule({ loading: false, error: true })
                    throw ex
                })
        },
        [setActivateModule, reload]
    )

    return {
        activateModule,
        activatingModule: activateModuleValue.loading,
        activateModuleError: activateModuleValue.error,
    }
}
