import { SetModuleActivationRequest, SetModuleActivationResponse } from "@tm/models"
import { ajaxAuth } from "@tm/utils"
import { WebserviceUrl } from "../../../webserviceUrls"

export function setModuleActivation(request: SetModuleActivationRequest): Promise<SetModuleActivationResponse | undefined> {
    const url = `${WebserviceUrl.AuthorityModulStore}/SetModuleActivation`
    const body = request

    return ajaxAuth({ url, body, method: "POST" })
}
