import { GetActivatableModulesResponse, UserContext, UserModuleType } from "@tm/models"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getModuleFromUserContext } from "@tm/context-distribution"
import { getActivatableModules } from "../modulStore/getActivatableModules"

export const KEY_ACTIVATABLE_MODULES = "misc_getActivatableModules"

export function useGetActivatableModules(
    userContext?: UserContext,
    disabled?: boolean
): { response?: GetActivatableModulesResponse; isLoading: boolean; error: unknown } {
    const demoActivationIsEnabled = !!userContext && !!getModuleFromUserContext(userContext, UserModuleType.DemoActivation)
    const { data, isLoading, error } = useQuery([KEY_ACTIVATABLE_MODULES], () => getActivatableModules(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: demoActivationIsEnabled && !disabled,
        retry: 3,
    })

    return { response: data, isLoading, error }
}

export function useFetchGetActivatableModules(userContext?: UserContext) {
    const queryClient = useQueryClient()
    const demoActivationIsEnabled = userContext?.modules?.some((x) => x.type === UserModuleType.DemoActivation)

    return useCallback(() => {
        if (!demoActivationIsEnabled) {
            return Promise.resolve(undefined)
        }
        return queryClient.fetchQuery([KEY_ACTIVATABLE_MODULES], () => getActivatableModules(), {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: 3,
        })
    }, [demoActivationIsEnabled, queryClient])
}
