type CachingOptions = {
    ttl: string
}

type ResponseCachingWhiteList = {
    [endpointUrl: string]: CachingOptions | string
}

export async function initCachingWhitelist() {
    window.cachingWhitelist = await loadCachingWhitelist()
}

function loadCachingWhitelist(): Promise<ResponseCachingWhiteList> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.onloadend = () => {
            if (xhr.status != 200 || !xhr.getResponseHeader("Content-Type")?.includes("application/json")) {
                reject()
                return
            }

            try {
                const content = JSON.parse(xhr.responseText)

                if (typeof content === "object") {
                    resolve(content)
                } else {
                    reject()
                }
            } catch {
                reject()
            }
        }

        xhr.open("GET", "/caching-whitelist.json")
        xhr.send()
    })
}
