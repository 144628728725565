import { useCallback, useEffect, useMemo } from "react"
import { Drawer as ComponentsDrawer, Box, Button, Icon } from "@tm/components"
import { Route, Router, useLocation } from "react-router"
import { LocationDescriptor, createMemoryHistory } from "history"

import { useMainHistory } from "@tm/utils"
import Morpheus, { BundleMicroContext, createMicro } from "@tm/morpheus"
import { channel } from "@tm/models"
import { OeParts, PartsAlternatives } from "@bundles/parts"

const modalHistory = createMemoryHistory()

type DrawerProps = {
    morpheus: Morpheus
}

export function Drawer({ morpheus }: DrawerProps) {
    const location = useLocation()
    const mainHistory = useMainHistory()

    const modalLocation = useMemo<LocationDescriptor | undefined>(() => {
        const searchParams = new URLSearchParams(location.search)
        const modalPathname = searchParams.get("mp")
        if (modalPathname) {
            return { pathname: modalPathname, search: searchParams.get("ms") ?? undefined }
        }
    }, [location.search])

    const isMorpheusViewOpen = useMemo(() => {
        return Morpheus.anyOpenView()
    }, [location.search])

    useEffect(() => {
        if (!modalLocation) {
            return
        }
        modalHistory.push(modalLocation)
    }, [modalLocation])

    const isOpen = useMemo(() => {
        return !!modalLocation
    }, [modalLocation])

    const handleClose = useCallback(() => {
        if (!isOpen) {
            return
        }

        const { pathname } = mainHistory.location
        const searchParams = new URLSearchParams(mainHistory.location.search)
        searchParams.delete("mp")
        searchParams.delete("ms")
        const url = [pathname, searchParams.toString()].filter((x) => !!x).join("?")
        mainHistory.push(url)
    }, [isOpen, mainHistory.location])

    useEffect(() => {
        const subscription = channel("GLOBAL").subscribe("DRAWER/CLOSE", handleClose)
        return () => {
            subscription?.()
        }
    }, [handleClose])

    const microContextState = useMemo(() => {
        return createMicro.bind(
            undefined,
            morpheus.context,
            { type: "component", bundle: "nobundle", component: "drawer" },
            morpheus.context.rootActions$,
            ""
        )
    }, [])

    return (
        // BundleMicroContext is neccessary for e.g. Article Comparison modal, because this modal is opened without using Morpheus and uses micros
        <BundleMicroContext.Provider value={microContextState}>
            <ComponentsDrawer
                PaperProps={{ sx: { overflow: "visible", width: "85vw", display: "grid", pl: 2 } }}
                open={isOpen && !isMorpheusViewOpen}
                anchor="right"
            >
                <Box position="absolute" left={-16} top="50%" sx={{ transform: "translateY(-50%)" }}>
                    <Button color="primary" onClick={handleClose} startIcon={<Icon name="close" />} />
                </Box>
                <Router history={modalHistory}>
                    <Route path="/parts/oe">
                        <OeParts />
                    </Route>
                    <Route path="/parts/alternatives">
                        <PartsAlternatives />
                    </Route>
                </Router>
            </ComponentsDrawer>
        </BundleMicroContext.Provider>
    )
}
