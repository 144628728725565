import { AuthToken, GetExternalTokenOptions } from "@tm/models"
import { ajaxAuth } from "@tm/utils"
import { WebserviceUrl } from "../../../webserviceUrls"
import { cacheToken, getCachedToken } from "./helpers"
import { ExternalToken } from "./models"

const tokenRequestRepo: { [key: string]: Promise<AuthToken> } = {}

function resetRequest(type: string, key?: string) {
    const repoKey = `${type}${key || ""}`
    if (Object.keys(tokenRequestRepo).indexOf(repoKey) !== -1) {
        delete tokenRequestRepo[repoKey]
    }
}

function getRequest(type: string, options?: GetExternalTokenOptions) {
    const { key, id, props, telesalesCustomerNo } = options || {}
    return new Promise<AuthToken>((resolve, reject) => {
        const itemKey = `token://${type}${key || id ? `:${key || id}` : ""}`

        const token = getCachedToken(itemKey)
        if (token) {
            resolve(token)
            return
        }

        const url = `${WebserviceUrl.AuthorityExternalAuthentication}/GetExternalToken`
        const body = { type, key, id, props, telesalesCustomerNo }

        ajaxAuth<ExternalToken>({ url, body, method: "GET" }).then(
            (response) => {
                if (response) {
                    cacheToken(itemKey, response)
                    resolve(response)
                } else {
                    reject(new Error("No token returned"))
                }

                resetRequest(type, key)
            },
            (reason) => reject(reason)
        )
    })
}

export function getExternalToken(type: string, options?: GetExternalTokenOptions): Promise<AuthToken> {
    const { key, id } = options || {}
    const repoKey = `${type}${key || id || ""}`
    if (!tokenRequestRepo[repoKey]) {
        tokenRequestRepo[repoKey] = getRequest(type, options)
    }

    return tokenRequestRepo[repoKey]
}
