import { useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ajax } from "@tm/utils"
import { AxiosError } from "axios"
import Error412 from "./error-412"

export default function ErrorComponent() {
    const [error, setError] = useState<AxiosError>()

    useEffect(() => {
        const unsub = ajax.onError((error) => {
            if (error.response?.status === 412) {
                setError(error)
            }
        })
        return () => {
            unsub()
        }
    }, [])

    if (error?.response?.status === 412) {
        const container = document.querySelector("#error")
        if (container) {
            return createPortal(<Error412 />, container)
        }
    }

    return null
}
