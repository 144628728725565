import { QueryClient } from "react-query"

export function createQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                retry: false,
                retryOnMount: false,
            },
        },
    })
}
