import { Container } from "@tm/nexus"
import { RegisteredModels, LogEntry } from "@tm/models"

export function loadStyles(url: string) {
    const baseStyle = document.querySelector<HTMLLinkElement>("#base-style")
    const appContainer = document.querySelector<HTMLDivElement>("#app")

    if (!baseStyle) {
        return
    }

    if (appContainer) {
        baseStyle.addEventListener("load", () => {
            appContainer.style.opacity = ""
        })

        if (!baseStyle) {
            appContainer.style.opacity = ""
            return
        }
    }

    baseStyle.href = url

    baseStyle.onerror = () => {
        console.log("Styles could not be loaded", url)
        Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", {
            type: "Component error",
            error: `Styles could not be loaded: ${url}`,
        } as LogEntry)
    }
}
