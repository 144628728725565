import { Component, RefObject, createRef } from "react"
import { bindSpecialReactMethods } from "@tm/utils"
import { Text, Button, MessageSystem } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"

export type BrowserCompabilityCheckConfig = {
    browserVersions: {
        chrome: string
        firefox: string
        edge: string
        safari: string
    }
    disableDownloads?: boolean
    titleMessage: string | number
    contentMessage: string | number
}

type Props = LocalizationProps & BrowserCompabilityCheckConfig

type State = {
    currentUrl: string
    hideIncompabilityWarning: boolean

    browserName: string
    browserVersion: string
    isDeprecatedVersion: boolean
    linkTo: string
    browserShortName: string
    stateChecked: boolean

    chromeVersion: string
    firefoxVersion: string
    safariVersion: string
    edgeVersion: string

    catalogName: string
    containerHeight?: string
}

const translationRegex = /\{\{(.*?)\}\}/
const recoverTranslationsRegex = /{\[{(.*)}\]}/

const LOCALSTORAGE_KEY = "ignoreCompabilityCheck"

class BrowserCompabilityCheck extends Component<Props, State> {
    outerWrapperRef = createRef<HTMLDivElement>()

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            currentUrl: "",
            hideIncompabilityWarning: false,

            linkTo: "",
            browserName: "",
            browserVersion: "",
            browserShortName: "",
            isDeprecatedVersion: false,
            stateChecked: false,

            chromeVersion: "100",
            firefoxVersion: "500",
            safariVersion: "10",
            edgeVersion: "100",

            catalogName: window.document.title,
            containerHeight: undefined,
        }
    }

    componentDidMount() {
        if (!this.isIgnored()) {
            this.getBrowserName()
        }

        this.checkHeight()
    }

    checkHeight = () => {
        setTimeout(() => {
            if (this.outerWrapperRef.current) {
                const { current } = this.outerWrapperRef
                let containerHeight = `${current.clientHeight}px`

                if (getInternetExplorerVersion() >= 0) {
                    containerHeight = `${current.scrollHeight}px`
                }

                if (!this.state.containerHeight) {
                    this.setState({ containerHeight })
                }
            }
        }, 200) // immediately setting the height will sometimes set the height w/o any styles loaded
    }

    componentDidUpdate() {
        this.checkHeight()
    }

    translate = (value: string | number) => {
        let translatedValue = this.props.localization.translate(value)

        if (typeof translatedValue == "string") {
            translatedValue = this.replaceTranslationPlaceHolders(translatedValue)
        }

        return translatedValue
    }

    replaceTranslationPlaceHolders = (text: string, props: any = this.state): string => {
        let translatedText = text
        let regexArray: RegExpExecArray | null

        while ((regexArray = translationRegex.exec(translatedText))) {
            translatedText = translatedText.replace(regexArray[0], props[regexArray[1]])
        }

        while ((regexArray = recoverTranslationsRegex.exec(translatedText))) {
            const value = regexArray[1]
            translatedText = translatedText.replace(recoverTranslationsRegex, `{{${value}}}`)
        }

        return translatedText
    }

    translateText = (value: string | number) => {
        let translatedValue = this.props.localization.translateText(value)

        if (translatedValue) {
            translatedValue = this.replaceTranslationPlaceHolders(translatedValue)
        }

        return translatedValue
    }

    render() {
        const { isDeprecatedVersion, browserName, containerHeight } = this.state

        if (this.isIgnored()) {
            return null
        }

        if (!isDeprecatedVersion && browserName != "Microsoft Internet Explorer") {
            return null
        }

        return (
            <div
                className="browers-compability-check"
                ref={this.outerWrapperRef}
                style={{ paddingBottom: containerHeight, position: "relative", zIndex: 1 }}
            >
                <div style={containerHeight ? { position: "fixed", width: "100%" } : {}}>
                    <MessageSystem title={this.translateText(this.props.titleMessage)} skin="warning" onButtonClick={this.hideSystemMessage}>
                        {this.renderToastChildren()}
                    </MessageSystem>
                </div>
            </div>
        )
    }

    renderToastChildren = () => {
        return this.state.browserName == "Microsoft Internet Explorer" ? this.renderIncompabilityMessage() : this.renderOutOfDateMessage()
    }

    renderIncompabilityMessage = () => {
        return (
            <div className="browser-compability-check__content">
                <Text className="browser-compability-check__content-message">
                    {this.translateText(this.props.contentMessage).replace(translationRegex, this.state.browserName)}
                </Text>
                {!this.props.disableDownloads && (
                    <div className="browser-compability-check__download-buttons" style={style.downloadButtons}>
                        <Button layout={["holo"]} target="_blank" linkTo="https://www.google.com/intl/de/chrome/">
                            {this.translateText(1611).replace(translationRegex, "Chrome")}
                        </Button>
                        <Button layout={["holo"]} target="_blank" linkTo="https://www.mozilla.org/de/firefox/new/">
                            {this.translateText(1611).replace(translationRegex, "Firefox")}
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    renderOutOfDateMessage = () => {
        return (
            <div className="browser-compability-check__content">
                <Text className="browser-compability-check__content-message">
                    {this.translateText(this.props.contentMessage).replace(translationRegex, this.state.browserName)}
                </Text>
                {!this.props.disableDownloads && (
                    <div className="browser-compability-check__download-buttons" style={style.downloadButtons}>
                        <Button layout={["holo"]} target="_blank" linkTo={this.state.linkTo}>
                            {this.translateText(1611).replace(translationRegex, "Chrome")}
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    hideSystemMessage = () => {
        this.setState({
            hideIncompabilityWarning: true,
        })

        this.handleIgnore()
    }

    isIgnored = () => {
        return window.sessionStorage.getItem(LOCALSTORAGE_KEY) == "true"
    }

    handleIgnore() {
        window.sessionStorage.setItem(LOCALSTORAGE_KEY, "true")
    }

    getBrowserName() {
        let userVersion = ""
        let browser = "unknown"
        let browserShortName = "unknown"

        const { userAgent } = navigator

        if (userAgent.indexOf("Firefox") > -1) {
            browser = "Mozilla Firefox"
            browserShortName = "firefox"
            userVersion = this.getBrowserVersion("Firefox")
        } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
            browser = "Opera"
            browserShortName = "opera"
            // Brauchen keine Version, da sie nicht unterstützt werden ^ˇ
        } else if (userAgent.indexOf("Trident") > -1) {
            browser = "Microsoft Internet Explorer"
            browserShortName = "iexplorer"
            userVersion = this.getBrowserVersion("rv")
        } else if (userAgent.indexOf("Edge") > -1) {
            browser = "Microsoft Edge"
            browserShortName = "edge"
            userVersion = this.getBrowserVersion("Edge")
        } else if (userAgent.indexOf("Chrome") > -1) {
            browser = "Google Chrome"
            browserShortName = "chrome"
            userVersion = this.getBrowserVersion("Chrome")
        } else if (userAgent.indexOf("Safari") > -1) {
            browser = "Safari"
            browserShortName = "safari"
            userVersion = this.getBrowserVersion("Safari")
        } else if (userAgent.indexOf("MSIE") >= 0) {
            browser = "Microsoft Internet Explorer"
            browserShortName = "msie"
            userVersion = this.getBrowserVersion("msie")
        }

        this.setState({ browserName: browser, browserShortName, browserVersion: userVersion })
        this.isBrowserDeprecated(browser, userVersion)
    }

    getBrowserVersion(browserName: string) {
        const { userAgent } = navigator
        const index = userAgent.indexOf(browserName) + browserName.length + 1
        let version = userAgent.substring(index)
        if (version.indexOf(".") > -1) {
            version = version.slice(0, version.indexOf("."))
        }
        return version
    }

    isBrowserDeprecated(browser: string, userVersion: any) {
        const { browserVersions: versions } = this.props

        if (!versions) {
            return
        }

        switch (browser) {
            case "Google Chrome":
                if (parseInt(userVersion) < parseInt(versions.chrome)) {
                    this.setState({
                        isDeprecatedVersion: true,
                        linkTo: "https://www.google.com/intl/de/chrome/",
                        stateChecked: true,
                    })
                }
                break
            case "Microsoft Edge":
                if (parseInt(userVersion) < parseInt(versions.edge)) {
                    this.setState({
                        isDeprecatedVersion: true,
                        linkTo: "https://www.microsoft.com/de-de/windows/microsoft-edge",
                        browserShortName: "edge",
                    })
                }
                break
            case "Mozilla Firefox":
                if (parseInt(userVersion) < parseInt(versions.firefox)) {
                    this.setState({
                        isDeprecatedVersion: true,
                        linkTo: "https://www.mozilla.org/de/firefox/new/",
                        browserShortName: "firefox",
                    })
                }
                break
            case "Safari":
                if (parseInt(userVersion) < parseInt(versions.safari)) {
                    this.setState({ isDeprecatedVersion: true, linkTo: "https://safari.de.softonic.com/" })
                }
                break
            default:
        }
    }
}

export default withLocalization(BrowserCompabilityCheck)

function getInternetExplorerVersion() {
    let rV = -1 // Return value assumes failure.

    if (navigator.appName == "Microsoft Internet Explorer" || navigator.appName == "Netscape") {
        const uA = navigator.userAgent
        const rE = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})")

        if (rE.exec(uA) != null) {
            rV = parseFloat(RegExp.$1)
        } else if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
            /* check for IE 11 */
            rV = 11
        }
    }
    return rV
}

const style = {
    downloadButtons: {
        paddingTop: ".5em",
    },
}

export function removeBrowserCompatibilityCheckFlag() {
    window.sessionStorage.setItem(LOCALSTORAGE_KEY, "false")
}
