import { History } from "history"
import { RegisteredModels, LogEntry } from "@tm/models"
import { Container } from "@tm/nexus"
import { ajax } from "@tm/utils"

import { matchLoginUrl } from "../login/matchLoginUrl"

// AJAX Error handler
export function registerAjaxErrorHandler(history: History) {
    ajax.onError((error) => {
        if (error.response?.status === 401) {
            const loginMatch = matchLoginUrl(history)
            if (!loginMatch && error.config.url) {
                const url = error.config.url.toLowerCase()
                const isServiceCall = url.indexOf("/data/tm.next") == 0
                const isExternalAuthentication =
                    url.includes("getalldatasession") || url.indexOf("/data/tm.next.authority/api/portal/externalauthentication") == 0

                if (isServiceCall && !isExternalAuthentication) {
                    Container.getInstance("Portal").callAction("logout", true, false)
                }
            }
        } else {
            Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", { type: "AJAX error", error } as LogEntry)
        }
    })
}
