import { ajaxAuth } from "@tm/utils"
import { WebserviceUrl } from "../../../webserviceUrls"
import { GetTokensRequest, GetTokensResponse } from "./models"

export async function getTokens(request: GetTokensRequest): Promise<GetTokensResponse> {
    const url = `${WebserviceUrl.AuthorityExternalAuthentication}/GetTokens`

    const response = await ajaxAuth<GetTokensResponse>({ url, body: request, method: "POST" }, undefined, undefined, true)

    if (!response) {
        throw new Error("No response returned")
    }

    return response
}
