import { ajax, removeStoredLogin } from "@tm/utils"
import { History } from "history"
import { GetAuthTokenByShareKeyRequest, GetAuthTokenByShareKeyResponse } from "@tm/models"
import { AppConfig } from "../data/loadConfiguration"
import { LoginMatch, matchLoginUrl } from "./matchLoginUrl"
import { trySingleSignOnAndRedirect } from "./trySingleSignOn"

export async function handleShareKeyAuthentication(
    appConfig: AppConfig,
    history: History<unknown>,
    languageId: string,
    catalog: string
): Promise<boolean> {
    const loginMatch = matchLoginUrl(history, false)
    if (!loginMatch) {
        return false
    }

    const { shareKey } = loginMatch

    if (!shareKey) {
        return false
    }

    try {
        const {
            login: { authId, serviceUrl },
        } = appConfig

        if (!authId) {
            return false
        }

        const response = await getAuthTokenByShareKey(serviceUrl, {
            shareKey,
            loginInterface: "frontend",
            languageId: parseInt(languageId) || undefined,
        })

        if (!response?.token) {
            removeStoredLogin()
            return false
        }

        if (trySingleSignOnAndRedirect({ ...loginMatch, token: response.token }, languageId, catalog, appConfig.params.disableAnalytics)) {
            return true
        }
    } catch {
        history.replace(`/login-error/${catalog}`)
    }

    return false
}

function storeLoginMatch(loginMatch: LoginMatch) {
    sessionStorage.setItem("loginMatch", JSON.stringify(loginMatch))
}

function getStoredLoginMatch(): LoginMatch | undefined {
    const storedLoginMatch = sessionStorage.getItem("loginMatch")

    if (storedLoginMatch) {
        return JSON.parse(storedLoginMatch)
    }
}

function getAuthTokenByShareKey(serviceUrl: string, request: GetAuthTokenByShareKeyRequest) {
    return ajax<GetAuthTokenByShareKeyResponse>({
        url: `${serviceUrl}/GetAuthTokenByShareKey`,
        method: "POST",
        body: request,
    })
}
